import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorMsg } from '../components/Components';
import AppContext from '../context';
import { adminSetPassword, adminVerifyToken } from '../Services';

export default function CreatePassword() {
	const {
		dispatch,
		baseUrl,
		setUserData,
		validateEmail,
		disableForms,
		errorResponse,
		useQueryString,
		notify,
	} = React.useContext(AppContext);
	const { register, errors, handleSubmit, getValues } = useForm({
		criteriaMode: 'all',
	});
	let history = useHistory();
	let { token } = useParams();
	/* let queryString = useQueryString();
    const token = queryString.get("token") || null; */
	const [errorText, setErrorText] = React.useState(null);

	/* Validate token */
	const { isLoading, isFetching, isError, data, error, status } = useQuery(
		['verify-token', token],
		() => adminVerifyToken(token),
		{
			retry: 0,
			manual: true,
			enabled: Boolean(token),
			onError: (error) => {
				if (error?.response?.status === 422) {
					setErrorText(
						'Invalid token, please try again or contact administrator!'
					);
				} else {
					setErrorText(
						'Can not process your request, please try again or contact administrator!'
					);
				}
			},
		}
	);

	React.useEffect(() => {
		if (!token) history.replace('/');
		//console.log(token, 'token...')
	}, [token]);

	const onSubmit = (values) => {
		disableForms('form-login', true);
		adminSetPassword({ ...values, token })
			.then((response) => {
				notify(
					'success',
					'Password Created!',
					'Password has been created successfully. Please login below!'
				);
				history.replace('/');
			})
			.catch((error) => {
				disableForms('form-login', false);
				errorResponse({ error, exclude: [401] });
			});
	};

	return (
		<div className='login-page'>
			<div className='container'>
				<div className='row justify-content-center'>
					<div className='col-md-8 col-lg-6 col-xl-5'>
						<div className='card overflow-hidden'>
							<div className='card-body'>
								<div className='p-2'>
									<div className='text-center mb-4'>
										<img
											src={`${baseUrl}assets/images/logo-edo.svg`}
											className='mr-2'
										/>
										<img src={`${baseUrl}assets/images/logo.svg`} width='60' />
									</div>
									<div>
										<h4 className='mb-3'>Create Password!</h4>
									</div>

									{isLoading && <div>Getting ready, please wait...</div>}
									{!isLoading && error && (
										<>
											{errorText && (
												<>
													<div className='mb-2 text-error'>{errorText}</div>
													<input
														type='button'
														onClick={() => history.push('/')}
														className='btn btn-danger btn-sm'
														value='Go to Login'
													/>
												</>
											)}
										</>
									)}

									{!isError && data && (
										<form
											className='form-horizontal'
											id='form-login'
											onSubmit={handleSubmit(onSubmit)}
										>
											<div className='form-group'>
												<label htmlFor='userpassword'>New Password</label>
												<input
													type='password'
													className='form-control'
													name='password'
													ref={register({
														required: 'Password is required!',
													})}
													placeholder='Enter New Password'
												/>
												<ErrorMsg errors={errors} name='password' />
											</div>

											<div className='form-group'>
												<label htmlFor='userpassword'>Confirm Password</label>
												<input
													type='password'
													className='form-control'
													name='password_confirmation'
													ref={register({
														required: 'Please confirm password!',
													})}
													placeholder='Enter Confirm Password'
												/>
												<ErrorMsg
													errors={errors}
													name='password_confirmation'
												/>
											</div>

											<button
												className='btn btn-success btn-block waves-effect waves-light bg-theme-success btn-main'
												type='submit'
											>
												Save Password
											</button>
										</form>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
