import * as React from 'react';
import AppContext from '../../../context';
import { Modal } from 'react-bootstrap';
import useModal from './../../../hooks/useModal';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMsg } from './../../../components/Components';
import moment from 'moment';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Webcam from 'react-webcam';
import PhotoSelector from '../../../components/PhotoSelector';

import Select from 'react-select';

const initValues = {
	state: 'Edo State',
	facilities: [],
	dob: null,
};

export default function RegisterData({
	currentIndex,
	submitEnrollement,
	occupations,
	facilitiesData,
	values,
	lgas,
	enrolleeImage,
	setEnrolleeImage,
	processImage,
	blobPhoto,
	setBlobPhoto,
	photoMode,
	setPhotoMode,
	editMode,
}) {
	const {
		dataURItoBlob,
		validateEmail,
		religions,
		marital_status,
		educational_status,
		special_needs,
	} = React.useContext(AppContext);

	const [defaultValues, setDefaultValues] = React.useState(values);
	const [webCamError, setWebCamError] = React.useState(null);
	const { modalState, setModalState, modalTitle, setModalTitle, closeModal } =
		useModal();
	const [facilities, setFacilities] = React.useState([]);
	const [facilityDv, setFacilityDv] = React.useState(null);
	const [selectedLga, setSelectedLga] = React.useState(values?.lga);

	const { register, errors, handleSubmit, getValues, control } = useForm({
		criteriaMode: 'all',
		mode: 'onChange',
		//defaultValues: values,
		defaultValues: values ? values : initValues,
	});

	const webcamRef = React.useRef(null);
	const photoInputRef = React.useRef(null);

	React.useEffect(() => {
		if (selectedLga && facilitiesData) {
			const filterFacility = facilitiesData.filter(
				(dt) => dt.lga === selectedLga
			);
			setFacilities(filterFacility);
		}
	}, [selectedLga, facilitiesData]);

	const capture = React.useCallback(() => {
		try {
			const imageSrc = webcamRef.current.getScreenshot();
			setEnrolleeImage(imageSrc);
			const blob = dataURItoBlob(imageSrc);
			setBlobPhoto(blob);
			handleCloseModal();
		} catch (error) {
			//console.log(error, 'capture error...')
		}
	}, [webcamRef]);

	const selectImage = () => {
		setPhotoMode(2);
		setTimeout(() => document.getElementById('image-input').click(), 100);
	};

	const initCaptureImage = () => {
		setModalTitle('Capture Enrollee Image');
		setPhotoMode(1);
		setModalState(true);
	};

	const handleCloseModal = () => {
		setModalState(false);
		//setDefaultValues(initValues);
	};

	const validateFacility = () =>
		getValues('facilities').length ? true : 'Select at least on facility';

	const validateRequiredField = (fieldName) => (value) => {
		// If business name is entered, then the specific field is required
		if (getValues('business_name') && !value) {
			return `${fieldName} is required!`;
		}
		return true;
	};

	const isMobile = window.innerWidth < 768;

	return (
		<>
			{currentIndex === 1 && (
				<>
					<h3 className='text-dark mb-3'>Enrollee Registration</h3>
					<h5 className='text-muted mb-2'>Personal Information</h5>

					<form
						id='form-register-data'
						onSubmit={handleSubmit(submitEnrollement)}
					>
						<PhotoSelector
							photoMode={photoMode}
							blobPhoto={blobPhoto}
							register={register}
							ErrorMsg={ErrorMsg}
							errors={errors}
							processImage={processImage}
							photoInputRef={photoInputRef}
							enrolleeImage={enrolleeImage}
							initCaptureImage={initCaptureImage}
							selectImage={selectImage}
							picture={values?.picture || ''}
						/>

						<div className='row register-data'>
							<div className='form-group col-md-4'>
								<label>
									First Name <span className='ml-1 text-danger'>*</span>
								</label>
								<input
									type='text'
									className='form-control'
									name='first_name'
									placeholder='First Name'
									ref={register({
										required: 'First name is required!',
									})}
								/>
								<ErrorMsg errors={errors} name='first_name' />
							</div>

							<div className='form-group col-md-4'>
								<label>Middle Name</label>
								<input
									type='text'
									className='form-control'
									name='middle_name'
									placeholder='Middle Name'
									ref={register}
								/>
							</div>

							<div className='form-group col-md-4'>
								<label>
									Surname <span className='ml-1 text-danger'>*</span>
								</label>
								<input
									type='text'
									className='form-control'
									name='last_name'
									placeholder='Surname'
									ref={register({
										required: 'Surname is required!',
									})}
								/>
								<ErrorMsg errors={errors} name='last_name' />
							</div>

							<div className='form-group col-md-6'>
								<label>
									Gender <span className='ml-1 text-danger'>*</span>
								</label>
								<select
									className='form-control'
									name='gender'
									ref={register({
										required: 'Gender is required!',
									})}
								>
									<option value=''>- Select -</option>
									<option value='Female'>Female</option>
									<option value='Male'>Male</option>
								</select>
								<ErrorMsg errors={errors} name='gender' />
							</div>

							<div className='form-group col-md-6 form-date-picker'>
								<label>
									Date of Birth <span className='ml-1 text-danger'>*</span>
								</label>

								<Controller
									name='dob'
									control={control}
									rules={{ required: 'Date of birth is required!' }}
									render={({ onChange, value }) => (
										<DatePicker
											//selected={new Date()}
											selected={value}
											onChange={onChange}
											className='form-control'
											placeholderText='Select birth date'
											dateFormat='MMMM d, yyyy'
											//defaultValue="15/05/2020"
										/>
									)}
								/>
								<ErrorMsg errors={errors} name='dob' />
							</div>

							<div className='form-group col-md-6'>
								<label>
									Religion <span className='ml-1 text-danger'>*</span>
								</label>
								<select
									className='form-control'
									name='religion'
									ref={register({
										required: 'Religion is required!',
									})}
								>
									<option value=''>- Select -</option>
									{religions &&
										religions.length > 0 &&
										religions.map((row, index) => {
											return (
												<option key={index} value={row}>
													{row}
												</option>
											);
										})}
								</select>
								<ErrorMsg errors={errors} name='religion' />
							</div>
							<div className='form-group col-md-6'>
								<label>
									Occupation
									<span className='ml-1 text-danger'>*</span>
								</label>
								<select
									className='form-control'
									name='occupation'
									ref={register({
										required: 'Occupation is required!',
									})}
								>
									<option value=''>- Select -</option>
									{occupations &&
										occupations.length > 0 &&
										occupations.map((row, index) => {
											return (
												<option key={index} value={row}>
													{row}
												</option>
											);
										})}
								</select>
								<ErrorMsg errors={errors} name='occupation' />
							</div>

							<div className='form-group col-md-6'>
								<label>Marital Status</label>
								<select
									className='form-control'
									name='marital_status'
									ref={register}
								>
									<option value=''>- Select -</option>
									{marital_status &&
										marital_status.length > 0 &&
										marital_status.map((row, index) => {
											return (
												<option key={index} value={row}>
													{row}
												</option>
											);
										})}
								</select>
							</div>

							<div className='form-group col-md-6'>
								<label>Educational Status</label>
								<select
									className='form-control'
									name='educational_status'
									ref={register}
								>
									<option value=''>- Select -</option>
									{educational_status &&
										educational_status.length > 0 &&
										educational_status.map((row, index) => {
											return (
												<option key={index} value={row}>
													{row}
												</option>
											);
										})}
								</select>
							</div>

							<div className='form-group col-md-6'>
								<label>Special Needs</label>
								<select
									className='form-control'
									name='special_needs'
									ref={register}
								>
									<option value=''>- Select -</option>
									{special_needs &&
										special_needs.length > 0 &&
										special_needs.map((row, index) => {
											return (
												<option key={index} value={row}>
													{row}
												</option>
											);
										})}
								</select>
							</div>

							<div className='form-group col-md-6'>
								<label>National ID Number (NIN)</label>
								<input
									type='number'
									className='form-control'
									name='nin'
									placeholder='National ID Number'
									ref={register}
								/>
							</div>

							<h5 className='text-muted py-2 pt-3 col-12'>
								Contact Information
							</h5>

							<div className='form-group col-md-6'>
								<label>
									Residential Address{' '}
									<span className='ml-1 text-danger'>*</span>
								</label>
								<input
									type='text'
									className='form-control'
									name='address'
									placeholder='Residential Address'
									ref={register({
										required: 'Residential address is required!',
									})}
								/>
								<ErrorMsg errors={errors} name='address' />
							</div>

							<div className='form-group col-md-6'>
								<label>
									LGA of residence <span className='ml-1 text-danger'>*</span>
								</label>
								<select
									className='form-control'
									name='lga'
									onChange={(e) => setSelectedLga(e.currentTarget.value)}
									value={selectedLga}
									ref={register({
										required: 'Local Govt. is required!',
									})}
								>
									<option value=''> - Select -</option>
									{lgas &&
										lgas.length > 0 &&
										lgas.map((row, index) => {
											return (
												<option key={row.code} value={row.name}>
													{row.name}
												</option>
											);
										})}
								</select>
								<ErrorMsg errors={errors} name='lga' />
							</div>

							{/* <div className="form-group col-md-2">
                        <label>State</label>
                        <select className="form-control"
                            name="state"
                            ref={register({
                                required: "State is required!"
                            })}>
                            <option value="Edo State">Edo State</option>
                        </select>
                        <ErrorMsg errors={errors} name="state" />
                    </div> */}

							<div className='form-group col-md-6'>
								<label>Ward</label>
								<input
									type='text'
									className='form-control'
									name='ward'
									placeholder='Ward'
									ref={register}
								/>
							</div>

							<div className='form-group col-md-6'>
								<label>Community</label>
								<input
									type='text'
									className='form-control'
									name='community'
									placeholder='Community'
									ref={register}
								/>
							</div>

							<div className='form-group col-md-6'>
								<label>
									Phone Number <span className='ml-1 text-danger'>*</span>
								</label>
								<input
									type='number'
									className='form-control'
									name='phone'
									placeholder='Phone Number'
									ref={register({
										required: 'Phone number is required!',
									})}
								/>
								<ErrorMsg errors={errors} name='phone' />
							</div>

							<div className='form-group col-md-6'>
								<label>Email Address</label>
								<input
									type='text'
									className='form-control'
									name='email'
									placeholder='Email Address'
									ref={register}
								/>
								<ErrorMsg errors={errors} name='email' />
							</div>

							<h5 className='text-muted py-2 pt-3 col-12'>
								Business Information
							</h5>
							<div className='form-group col-12'>
								<label>Business Name</label>
								<input
									type='text'
									className='form-control'
									name='business_name'
									placeholder='Business Name'
									ref={register}
								/>
							</div>

							<div className='form-group col-md-8'>
								<label>Office Address</label>
								<input
									type='text'
									className='form-control'
									name='office_address'
									placeholder='Office Address'
									ref={register({
										validate: validateRequiredField('Office Address'),
									})}
								/>
								<ErrorMsg errors={errors} name='office_address' />
							</div>

							<div className='form-group col-md-4'>
								<label>Office LGA</label>
								<select
									className='form-control'
									name='office_lga'
									ref={register({
										validate: validateRequiredField('Office LGA'),
									})}
								>
									<option value=''> - Select -</option>
									{lgas &&
										lgas.length > 0 &&
										lgas.map((row, index) => {
											return (
												<option key={row.code} value={row.name}>
													{row.name}
												</option>
											);
										})}
								</select>
								<ErrorMsg errors={errors} name='office_lga' />
							</div>

							<h5 className='text-muted py-2 pt-3 col-12'>
								Medical Information
							</h5>

							<div className='form-group col-md-4'>
								<label>Genotype</label>
								<select className='form-control' ref={register} name='genotype'>
									<option value=''>- Select -</option>
									<option value='AA'>AA</option>
									<option value='AS'>AS</option>
									<option value='SS'>SS</option>
									<option value='AC'>AC</option>
									<option value='SC'>SC</option>
								</select>
							</div>
							<div className='form-group col-md-4'>
								<label>Blood Group</label>
								<select
									className='form-control'
									ref={register}
									name='blood_group'
								>
									<option value=''>- Select -</option>
									<option value='A+'>A+</option>
									<option value='A-'>A-</option>
									<option value='B+'>B+</option>
									<option value='B-'>B-</option>
									<option value='AB+'>AB+</option>
									<option value='AB-'>AB-</option>
									<option value='O+'>O+</option>
									<option value='O-'>O-</option>
								</select>
							</div>
							<div className='form-group col-md-4'>
								<label>Allergies</label>
								<input
									type='text'
									className='form-control'
									name='allergies'
									ref={register}
									placeholder='Allergies'
								/>
							</div>

							<h5 className='text-muted py-2 pt-3 col-12'>
								Insurance Information
							</h5>

							<div className='form-group col-md-12'>
								<label>
									Primary Facility <span className='ml-1 text-danger'>*</span>
									<br />
									<span className='text-muted font-size-12'>
										You can select more than one facility.
									</span>
								</label>

								<Controller
									name='facilities'
									control={control}
									rules={{ validate: validateFacility }}
									render={({ onChange, value }) => (
										<Select
											defaultValue={value}
											onChange={onChange}
											isMulti
											options={facilities}
											className='basic-multi-select'
											classNamePrefix='form-control'
										/>
									)}
								/>
								<ErrorMsg errors={errors} name='facilities' />
							</div>

							<hr />
						</div>
						<div className='text-right'>
							<button
								type='submit'
								disabled={
									facilitiesData.length === 0 ||
									lgas.length === 0 ||
									occupations.length === 0 ||
									(editMode && !values)
								}
								className='btn btn-success btn-main'
							>
								{editMode ? 'Update Enrollee Info' : 'Save & Continue'}
							</button>
						</div>
					</form>

					<Modal
						show={modalState}
						onHide={handleCloseModal}
						animation={false}
						backdrop='static'
						keyboard={false}
					>
						<Modal.Header closeButton>
							<Modal.Title>{modalTitle}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{webCamError && <div className='text-error'>{webCamError}</div>}
							{!webCamError && (
								<Webcam
									audio={false}
									ref={webcamRef}
									screenshotFormat='image/jpeg'
									width={`100%`}
									onUserMediaError={() =>
										setWebCamError(
											'No camera found on device/Permission denied!'
										)
									}
									videoConstraints={{
										facingMode: isMobile ? 'user' : { exact: 'environment' },
									}}
								/>
							)}
						</Modal.Body>
						<Modal.Footer>
							<input
								type='button'
								onClick={handleCloseModal}
								className='btn btn-default'
								value='Cancel'
							/>
							<button
								className='btn btn-success btn-main'
								onClick={capture}
								disabled={webCamError}
								type='button'
							>
								Capture Photo
							</button>
						</Modal.Footer>
					</Modal>
				</>
			)}
		</>
	);
}
