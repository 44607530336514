import { Fragment, useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import AppContext from '../../../context';
import { Currency, ErrorMsg } from './../../../components/Components';
import useModal from './../../../hooks/useModal';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const initValues = {
	is_family_plan: 'false',
	start_date: new Date(),
};

export default function RegisterPackage({
	currentIndex,
	handleSubmitPackage,
	packages,
}) {
	const { dispatch, checkArray, checkObject, capitalize } =
		useContext(AppContext);
	const { register, errors, handleSubmit, control } = useForm({
		defaultValues: initValues,
		criteriaMode: 'all',
		mode: 'onChange',
	});

	const [benefits, setBenefits] = useState([]);
	const [is_family_plan, set_is_family_plan] = useState(
		initValues.is_family_plan
	);

	/* Modal hook */
	const { modalState, setModalState, modalTitle, setModalTitle, closeModal } =
		useModal();

	const showPlan = ({ benefits, name }) => {
		const groupData = benefits.reduce(
			(h, dt) =>
				Object.assign(h, {
					[dt.type]: (h[dt.type] || []).concat({ id: dt.id, name: dt.name }),
				}),
			{}
		);
		setBenefits(groupData);
		setModalTitle(name);
		setModalState(true);
	};

	return (
		<>
			{currentIndex === 2 && (
				<>
					<h3 className='text-dark mb-2'>Select Package</h3>
					<p className='mb-3 text-muted'>
						Select an insurance place which suits your need.
					</p>

					<form
						id='form-register-package'
						onSubmit={handleSubmit(handleSubmitPackage)}
					>
						<div className='row'>
							<div className='form-group col-md-4'>
								<label>Plan Type</label>
								<select
									className='form-control'
									name='is_family_plan'
									onChange={(e) => set_is_family_plan(e.target.value)}
									//onChange={e => console.log(e.target.value, 'true', 'e.target.value')}
									ref={register}
								>
									<option value='false'>Standard Plan</option>
									<option value='true'>Family Plan</option>
								</select>
							</div>

							<div className='form-group col-md-4 form-date-picker'>
								<label>Start Date</label>
								<Controller
									name='start_date'
									control={control}
									rules={{ required: 'Start date is required!' }}
									render={({ onChange, value }) => (
										<DatePicker
											selected={value}
											onChange={onChange}
											className='form-control'
											placeholderText='Select start date'
											dateFormat='MMMM d, yyyy'
										/>
									)}
								/>
								<ErrorMsg errors={errors} name='start_date' />
							</div>

							<div className='form-group col-md-4'>
								<label>Duration</label>
								<select
									className='form-control'
									name='duration'
									ref={register({
										required: 'Duration is required!',
									})}
								>
									<option value=''>- Select -</option>
									<option value='12'>1 Year</option>
									<option value='24'>2 Years</option>
									<option value='36'>3 Years</option>
								</select>
								<ErrorMsg errors={errors} name='duration' />
							</div>
						</div>

						<hr />

						{is_family_plan === 'true' &&
							checkArray(packages) &&
							packages
								.sort((a, b) => a.amount - b.amount)
								.map((row) =>
									row?.is_family_allowed && row?.is_public ? (
										<>
											<div
												key={row.id}
												className='custom-control custom-radio custom-control-select'
											>
												<input
													type='radio'
													id={`plan-${row.id}`}
													value={row.id}
													name='package_id'
													className='custom-control-input'
													ref={register({
														required: 'Please select a plan!',
													})}
												/>
												<label
													className='custom-control-label custom-control-label-select'
													htmlFor={`plan-${row.id}`}
												>
													<span>{row.name}</span>
													{is_family_plan === 'true' && (
														<span>
															<Currency value={row.family_amount} />
														</span>
													)}
													{is_family_plan === 'false' && (
														<span>
															<Currency value={row.amount} />
														</span>
													)}
													<span onClick={() => showPlan(row)}>
														View Benefits
													</span>
												</label>
											</div>
										</>
									) : (
										''
									)
								)}

						{is_family_plan === 'false' &&
							checkArray(packages) &&
							packages
								.sort((a, b) => a.amount - b.amount)
								.map((row) =>
									row?.is_public ? (
										<>
											<div
												key={row.id}
												className='custom-control custom-radio custom-control-select'
											>
												<input
													type='radio'
													id={`plan-${row.id}`}
													value={row.id}
													name='package_id'
													className='custom-control-input'
													ref={register({
														required: 'Please select a plan!',
													})}
												/>
												<label
													className='custom-control-label custom-control-label-select'
													htmlFor={`plan-${row.id}`}
												>
													<span>{row.name}</span>
													{is_family_plan === 'true' && (
														<span>
															<Currency value={row.family_amount} />
														</span>
													)}
													{is_family_plan === 'false' && (
														<span>
															<Currency value={row.amount} />
														</span>
													)}
													<span onClick={() => showPlan(row)}>
														View Benefits
													</span>
												</label>
											</div>
										</>
									) : (
										''
									)
								)}
						<ErrorMsg errors={errors} name='package_id' />

						<div className='text-right'>
							<button
								type='submit'
								disabled={!checkArray(packages)}
								className='btn btn-success btn-main'
							>
								Continue
							</button>
						</div>
					</form>
				</>
			)}

			<Modal show={modalState} onHide={closeModal} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<input
						type='text'
						className='form-control'
						placeholder='Search...'
						name='search'
						onChange={(e) => {
							const search = e.target.value.toLowerCase();

							const filteredBenefits = Object.entries(benefits)[0][1].filter(
								(benefit) => benefit.name.toLowerCase().includes(search)
							);

							setBenefits({ null: filteredBenefits });
						}}
					/>

					{checkObject(benefits) && (
						<table
							width='100%'
							className='table table-centered table-striped table-list-plan'
						>
							{Object.entries(benefits).map((value, key) => {
								return (
									<Fragment key={value[0]}>
										<thead>
											<tr>
												<th width='100%'>{capitalize(value[0])}</th>
											</tr>
										</thead>
										<tbody>
											{value[1] &&
												value[1].map((row, index) => {
													return (
														<tr key={row.id}>
															<td scope='row'>{row.name}</td>
														</tr>
													);
												})}
										</tbody>
									</Fragment>
								);
							})}
						</table>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
}
