import * as React from 'react';
import { Link, withRouter, NavLink } from 'react-router-dom';
import AppContext from './../context';
import routes from './../routes';
import MetisMenu from '@metismenu/react';

const Sidemenu = ({ children, history }) => {
	const { dispatch, userToken, logoutUser } = React.useContext(AppContext);

	return (
		<div id='sidebar-menu'>
			<MetisMenu className='list-unstyled'>
				{routes
					.filter((dt) => dt?.menu?.status && dt?.menu?.main)
					.sort((a, b) => (a.menu.order > b.menu.order ? 1 : -1))
					.map((row, index) => {
						if (row?.menu?.hasSub) {
							return (
								<li key={row.id}>
									<a
										style={{ cursor: 'pointer' }}
										className='has-arrow waves-effect'
									>
										<i className='bx bx-layout'></i>
										<span>{row?.menu?.title}</span>
									</a>
									<ul className='sub-menu' aria-expanded='false'>
										{/*  */}

										{routes
											.filter(
												(dt) => dt?.menu?.status && dt?.menu?.parent === row.id
											)
											.sort((a, b) => (a.menu.order > b.menu.order ? 1 : -1))
											.map((rowSub) => {
												return (
													<li key={rowSub.id}>
														<NavLink
															exact={row?.exact}
															to={rowSub.path}
															className='waves-effect sidebar-menu-link'
														>
															{rowSub?.menu?.icon ? (
																<i className={rowSub.menu.icon}></i>
															) : (
																''
															)}
															<span>{rowSub?.menu?.title}</span>
														</NavLink>
													</li>
												);
											})}

										{/*  */}
									</ul>
								</li>
							);
						}

						return (
							<li key={row.id}>
								<NavLink
									exact={row?.exact}
									to={row.path}
									className='waves-effect sidebar-menu-link'
								>
									{row?.menu?.icon ? <i className={row.menu.icon}></i> : ''}
									<span>{row?.menu?.title}</span>
								</NavLink>
							</li>
						);
					})}
			</MetisMenu>
		</div>
	);
};
export default withRouter(Sidemenu);
