// import * as React from "react";

export const initialState = {
	baseUrl: process.env.REACT_APP_BASE_URL,
	pageTitle: process.env.REACT_APP_TITLE,
	//mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
	mapboxApiAccessToken:
		'pk.eyJ1Ijoicmhvbm5pcXVlIiwiYSI6ImNrbHhqZzkzNzNha3kybm4xMGxneTh1MHkifQ.zur5smoMG6AZoH1_0Vb4HA',
	userData: null,
	userToken: null,
	userTokenExp: null,
	appIsReady: false,
	hasLoginError: false,
	formValues: {},
	modalTitle: '',
	formActionType: 1,
	stateData: null,
	currentPath: '',
	showPageTitle: true,
	benefitTypes: [
		{ value: 'health', label: 'Health Benefit' },
		{ value: 'drug', label: 'Drug Benefit' },
		{ value: 'lab', label: 'Lab Benefit' },
	],
	paymentMethods: [
		//card,deposit,cash,pos,exemption
		// { value: "card", label: "Card", disabled: false },
		// { value: 'deposit', label: 'Deposit', disabled: false },
		{ value: 'bank_payment', label: 'Bank Payment', disabled: false },
		// { value: "Cash", label: "Cash", disabled: false },
		// { value: 'pos', label: 'POS', disabled: false },
		// { value: "exemption", label: "Payment by Exemption", disabled: false },
	],
	paymentVendors: [
		{ value: 'interswitch', label: 'Interswitch', disabled: false },
		// { value: 'remita', label: 'Remita', disabled: false },
		// { value: "paystack", label: "Paystack", disabled: false },
	],
	exemptions: [
		{ value: 1, label: 'Formal Sector - Goverment Employee' },
		{ value: 2, label: 'Vulnerable Group - BHCPS' },
	],
	faqFieldTypes: [
		{ value: 'textfield', label: 'Text Field' },
		{ value: 'textarea', label: 'Multiline Text Field' },
		{ value: 'radio', label: 'Radio Button Option' },
		{ value: 'checkbox', label: 'Checkbox Option' },
		{ value: 'select', label: 'Select Dropdown Option' },
		{ value: 'file', label: 'File Field' },
	],

	marital_status: [
		'Married',
		'Single',
		'Widow',
		'Widower',
		'Single Parent',
		'Divorced',
	],
	educational_status: [
		'Post Secondary',
		'Secondary',
		'Primary',
		'No Education',
	],
	special_needs: [
		'Physically Challenged',
		'Pregnant Woman',
		'CU5',
		'Aged',
		'NA',
	],
	religions: ['Christian', 'Muslim', 'Traditional', 'Others'],
};

export const AppReducer = (initialState, action) => {
	switch (action.type) {
		case 'APP_READY': {
			//console.log(initialState.formValues, 'initialState...')
			return {
				...initialState,
				appIsReady: true,
				userToken: action.userToken,
				userData: action.userData,
				userTokenExp: action.userTokenExp,
			};
		}
		case 'LOGIN': {
			return {
				...initialState,
				userToken: action.userToken,
				userData: action.userData,
			};
		}
		case 'LOGOUT':
			return {
				...initialState,
				userToken: null,
				userData: null,
				userTokenExp: null,
			};
		case 'UPDATE_FORM':
			const name = action.name;
			const value = action.value;
			let { formValues } = initialState;
			formValues = { ...formValues, [name]: value };
			return {
				...initialState,
				formValues,
			};
		case 'CLEAR_FORM':
			return {
				...initialState,
				formValues: {},
			};
		case 'SET_STATE':
			const stateName = action.name;
			const stateValue = action.value;
			return {
				...initialState,
				[stateName]: stateValue,
			};
		case 'SET_PAGE_TITLE':
			return {
				...initialState,
				pageTitle: action.title,
			};
		case 'SET_PATH_NAME':
			return {
				...initialState,
				currentPath: action.pathname,
			};
		case 'SHOW_PAGE_TITLE':
			return {
				...initialState,
				showPageTitle: action.showPageTitle,
			};
		default:
			return initialState;
	}
};
