import ChangePassword from './pages/change-password';
import CreatePassword from './pages/create-password';
import Dashboard from './pages/dashboard';
import Enrollment from './pages/enrollment';
import NotEnrolled from './pages/enrollment/not-enrolled';
import EnrolleeRegister from './pages/enrollment/register';
import RegisterDependant from './pages/enrollment/register/form-dependant';
import Login from './pages/login';
import NotFound from './pages/not-found';

const routes = [
	{
		id: 2,
		path: '/login',
		component: Login,
		auth: false,
		exact: true,
		route: true,
		pageTitle: 'Login',
	},
	{
		id: 221,
		path: '/create-password/:token',
		component: CreatePassword,
		auth: false,
		exact: false,
		route: true,
		pageTitle: 'Create Password',
	},
	{
		id: 222,
		path: '/change-password',
		component: ChangePassword,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Change Password',
	},
	// {
	// 	id: 7,
	// 	path: '/enrollees',
	// 	component: Entrollment,
	// 	title: 'Enrollees',
	// 	auth: true,
	// 	exact: true,
	// 	route: true,
	// 	pageTitle: 'Manage Enrolles',
	// 	menu: {
	// 		status: true,
	// 		title: 'Manage Enrollees',
	// 		order: 2,
	// 		main: true,
	// 		icon: 'bx bx-user-circle',
	// 	},
	// },
	{
		id: 7,
		menu: {
			status: true,
			title: 'Enrollment',
			main: true,
			hasSub: true,
			order: 6,
			icon: 'bx bx-user-circle',
		},
	},
	{
		id: 8,
		path: '/enrollees',
		component: Enrollment,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Active Enrollees',
		menu: {
			status: true,
			title: 'Enrollees',
			parent: 7,
			order: 1,
			icon: 'bx bx-cog',
		},
	},
	{
		id: 9,
		path: '/enrollee/inactive-enrollees',
		component: NotEnrolled,
		auth: true,
		exact: false,
		route: true,
		pageTitle: 'Registrants',
		menu: {
			status: true,
			title: 'Registrants',
			parent: 7,
			order: 2,
			icon: 'bx bx-cog',
		},
	},
	{
		id: 81,
		path: '/enrollee/create',
		component: EnrolleeRegister,
		auth: true,
		exact: true,
		route: true,
		pageTitle: 'Create Enrollee',
	},
	{
		id: 82,
		path: '/enrollee/add-dependant',
		component: RegisterDependant,
		auth: true,
		exact: true,
		route: true,
		pageTitle: 'Add Enrollee Dependant',
	},
	{
		id: 1,
		path: '/',
		component: Dashboard,
		title: 'Dashboard',
		auth: true,
		exact: true,
		route: true,
		pageTitle: 'Dashboard',
		menu: {
			status: true,
			title: 'Dashboard',
			order: 1,
			main: true,
			icon: 'bx bx-home-circle',
		},
	},
	{
		id: 404,
		path: '/*',
		component: NotFound,
		exact: false,
		route: true,
		pageTitle: 'Page Not Found!',
		menu: { status: false },
	},
];

export default routes;
