import * as React from 'react';
import AppContext from '../../../context';
import {
	occupationsList,
	enrolleeAddDependant,
	selectLgas,
	facilitiesList,
} from '../../../Services';
import { useQuery, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import useModal from './../../../hooks/useModal';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMsg } from './../../../components/Components';
import { Modal } from 'react-bootstrap';

import useFormState from './../../../hooks/useFormState';

import Webcam from 'react-webcam';
import PhotoSelector from '../../../components/PhotoSelector';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';

let initValues = {
	state: 'Edo State',
	facilities: [],
	dob: null,
};

export default function RegisterDependant({ location }) {
	const history = useHistory();
	const {
		dispatch,
		dataURItoBlob,
		notify,
		errorResponse,
		validateEmail,
		marital_status,
		educational_status,
		special_needs,
	} = React.useContext(AppContext);

	const [defaultValues] = React.useState(() => {
		//console.log(location.state?.enrolleeData, 'location.state?.enrolleeData...')

		const { address, lga, facilities } = location.state?.enrolleeData;
		initValues = {
			...initValues,
			address,
			lga,
			facilities,
		};

		return initValues;
	});

	const { register, errors, handleSubmit, getValues, control } = useForm({
		criteriaMode: 'all',
		mode: 'onChange',
		defaultValues,
	});

	const [enrolleeData, setEnrolleeData] = React.useState({});
	const [values, setValues] = React.useState({});
	const [lgas, setLgas] = React.useState(null);
	const [enrolleeImage, setEnrolleeImage] = React.useState(null);
	const [enrolleeImageData, setEnrolleeImageData] = React.useState(null);
	const [blobPhoto, setBlobPhoto] = React.useState('');
	const [photoMode, setPhotoMode] = React.useState(1);
	const [webCamError, setWebCamError] = React.useState(null);
	const [occupations, setOccupations] = React.useState(null);
	const [facilitiesData, setFacilitiesData] = React.useState([]);
	const [facilities, setFacilities] = React.useState([]);
	const [facilityDv, setFacilityDv] = React.useState(null);
	const [selectedLga, setSelectedLga] = React.useState(defaultValues?.lga);

	const webcamRef = React.useRef(null);
	const photoInputRef = React.useRef(null);

	const { toggleFormState: tfData } = useFormState('form-dependant');
	const { modalState, setModalState, modalTitle, setModalTitle, closeModal } =
		useModal();

	React.useEffect(() => {
		if (selectedLga && facilitiesData) {
			const filterFacility = facilitiesData.filter(
				(dt) => dt.lga === selectedLga
			);
			setFacilities(filterFacility);
		}
	}, [selectedLga, facilitiesData]);

	/* Local Govt. */
	useQuery('select-lgas', selectLgas, {
		onError: (error) => setLgas(null),
		onSuccess: (data) => setLgas(data),
	});

	/* Facilities. */
	useQuery('select-facilities', facilitiesList, {
		onError: (error) => setFacilitiesData([]),
		onSuccess: (data) => {
			if (data.length > 0) {
				const setFac = data.map((dt) => ({
					value: dt.id,
					label: `${dt.name} (${dt.enrollees_count})`,
					lga: dt.lga,
				}));
				setFacilitiesData(setFac);
			}
		},
	});

	/* Occupations. */
	useQuery('select-occupations', occupationsList, {
		onError: (error) => setOccupations(null),
		onSuccess: (data) => setOccupations(data),
	});

	const { mutate: handleSubmitDependant } = useMutation(
		({ id, values }) => enrolleeAddDependant({ id, values }),
		{
			onSuccess: (data) => {
				notify(
					'success',
					'Dependant Added',
					'Dependant successfully added to enrollee!'
				);
				history.push('/enrollees');
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => tfData(false),
		}
	);

	/* Handle step 1 */
	const submitEnrollement = async (data) => {
		tfData(true, 'saving dependant...');
		let formData = new FormData();
		//data = { ...data, dob: moment(data?.dob).format('YYYY-MM-DD'), photo: data?.photo[0] };
		data = {
			...data,
			dob: moment(data?.dob).format('YYYY-MM-DD'),
			nationality: 'Nigerian',
			photo: photoMode === 2 ? data?.photo[0] : blobPhoto,
			facilities:
				(data?.facilities &&
					JSON.stringify(data.facilities.map((dt) => dt.value))) ||
				[],
			state: 'Edo State',
		};
		Object.entries(data).forEach(async ([key, value]) => {
			await new Promise((resolve) => formData.append(key, value));
		});

		handleSubmitDependant({ id: enrolleeData?.id, values: formData });
	};

	React.useEffect(() => {
		const imageInput = document.getElementById('image-input');
		let imageBtns = document.querySelectorAll('.btn-image-select');
		imageBtns.forEach(function (btn, index) {
			btn.addEventListener('click', () => {
				imageInput.click();
			});
		});

		return () => {
			setValues({});
			setEnrolleeImage(null);
			setEnrolleeImageData(null);
		};
	}, []);

	const processImage = (event) => {
		const file = event.target.files || event.dataTransfer.files;
		if (file[0]) {
			setEnrolleeImageData(file[0]);
			let reader = new FileReader();
			reader.onload = (e) => {
				setEnrolleeImage(e.target.result);
			};
			reader.readAsDataURL(file[0]);
		}
	};

	React.useEffect(() => {
		if (location.state?.enrolleeData) {
			setEnrolleeData(location.state?.enrolleeData);
		} else {
			history.push('/enrollees');
		}
	}, [location.state?.enrolleeData]);

	const capture = React.useCallback(() => {
		try {
			const imageSrc = webcamRef.current.getScreenshot();
			setEnrolleeImage(imageSrc);
			const blob = dataURItoBlob(imageSrc);
			setBlobPhoto(blob);
			handleCloseModal();
		} catch (error) {
			console.log(error, 'capture error...');
		}
	}, [webcamRef]);

	const initCaptureImage = () => {
		setModalTitle('Capture Enrollee Image');
		setPhotoMode(1);
		setModalState(true);
	};

	const selectImage = () => {
		setPhotoMode(2);
		setTimeout(() => document.getElementById('image-input').click(), 100);
	};

	const handleCloseModal = () => {
		setModalState(false);
	};

	const validateFacility = () =>
		getValues('facilities').length ? true : 'Select at least on facility';

	return (
		<>
			<div className='row'>
				<div className='col-md-12'>
					<div className='card'>
						<div className='card-body p-0'>
							<div className='row'>
								<div className='col-md-8'>
									<div className='card-body'>
										<form
											id='form-dependant'
											onSubmit={handleSubmit(submitEnrollement)}
										>
											<PhotoSelector
												photoMode={photoMode}
												blobPhoto={blobPhoto}
												register={register}
												ErrorMsg={ErrorMsg}
												errors={errors}
												processImage={processImage}
												photoInputRef={photoInputRef}
												enrolleeImage={enrolleeImage}
												initCaptureImage={initCaptureImage}
												selectImage={selectImage}
												dependant={true}
											/>

											<div className='row register-data'>
												<div className='form-group col-md-4'>
													<label>First Name</label>
													<input
														type='text'
														className='form-control'
														name='first_name'
														placeholder='First Name'
														defaultValue={defaultValues.first_name}
														ref={register({
															required: 'First name is required!',
														})}
													/>
													<ErrorMsg errors={errors} name='first_name' />
												</div>
												<div className='form-group col-md-4'>
													<label>Middle Name</label>
													<input
														type='text'
														className='form-control'
														name='middle_name'
														placeholder='Middle Name'
														defaultValue={defaultValues.middle_name}
														ref={register}
													/>
												</div>
												<div className='form-group col-md-4'>
													<label>Last Name</label>
													<input
														type='text'
														className='form-control'
														name='last_name'
														placeholder='Last Name'
														defaultValue={defaultValues.last_name}
														ref={register({
															required: 'Last name is required!',
														})}
													/>
													<ErrorMsg errors={errors} name='last_name' />
												</div>

												<div className='form-group col-md-6'>
													<label>Gender</label>
													<select
														className='form-control'
														name='gender'
														defaultValue={defaultValues.gender}
														ref={register({
															required: 'Gender is required!',
														})}
													>
														<option value=''>- Select -</option>
														<option value='Female'>Female</option>
														<option value='Male'>Male</option>
													</select>
													<ErrorMsg errors={errors} name='gender' />
												</div>
												<div className='form-group col-md-6 form-date-picker'>
													<label>Date of Birth</label>
													<Controller
														name='dob'
														control={control}
														rules={{ required: 'Date of birth is required!' }}
														render={({ onChange, value }) => (
															<DatePicker
																selected={value}
																onChange={onChange}
																className='form-control'
																placeholderText='Select birth date'
																dateFormat='MMMM d, yyyy'
															/>
														)}
													/>
													<ErrorMsg errors={errors} name='dob' />
												</div>

												<div className='form-group col-md-6'>
													<label>Religion</label>
													<select
														className='form-control'
														name='religion'
														defaultValue={defaultValues.religion}
														ref={register({
															required: 'Religion is required!',
														})}
													>
														<option value=''>- Select -</option>
														<option value='Christian'>Christian</option>
														<option value='Muslim'>Muslim</option>
													</select>
													<ErrorMsg errors={errors} name='religion' />
												</div>
												<div className='form-group col-md-6'>
													<label>Occupation</label>
													<select
														className='form-control'
														name='occupation'
														defaultValue={defaultValues.primary_facility_id}
														ref={register({
															required: 'Occupation is required!',
														})}
													>
														<option value=''>- Select -</option>
														{occupations &&
															occupations.length > 0 &&
															occupations.map((row, index) => {
																return (
																	<option key={index} value={row}>
																		{row}
																	</option>
																);
															})}
													</select>
													<ErrorMsg errors={errors} name='occupation' />
												</div>

												<div className='form-group col-md-4'>
													<label>Marital Status</label>
													<select
														className='form-control'
														name='marital_status'
														ref={register}
													>
														<option value=''>- Select -</option>
														{marital_status &&
															marital_status.length > 0 &&
															marital_status.map((row, index) => {
																return (
																	<option key={index} value={row}>
																		{row}
																	</option>
																);
															})}
													</select>
												</div>

												<div className='form-group col-md-4'>
													<label>Educational Status</label>
													<select
														className='form-control'
														name='educational_status'
														ref={register}
													>
														<option value=''>- Select -</option>
														{educational_status &&
															educational_status.length > 0 &&
															educational_status.map((row, index) => {
																return (
																	<option key={index} value={row}>
																		{row}
																	</option>
																);
															})}
													</select>
												</div>

												<div className='form-group col-md-4'>
													<label>Dependant Type</label>
													<select
														className='form-control'
														name='dependant_type'
														ref={register({
															required: 'Dependant type is required!',
														})}
													>
														<option value=''>- Select -</option>
														<option value='spouse'>Spouse</option>
														<option value='child'>Child</option>
													</select>
													<ErrorMsg errors={errors} name='dependant_type' />
												</div>

												<div className='form-group col-md-6'>
													<label>Special Needs</label>
													<select
														className='form-control'
														name='special_needs'
														ref={register}
													>
														<option value=''>- Select -</option>
														{special_needs &&
															special_needs.length > 0 &&
															special_needs.map((row, index) => {
																return (
																	<option key={index} value={row}>
																		{row}
																	</option>
																);
															})}
													</select>
												</div>

												<div className='form-group col-md-6'>
													<label>National ID Number (NIN)</label>
													<input
														type='number'
														className='form-control'
														name='nin'
														placeholder='National ID Number'
														ref={register}
													/>
												</div>

												<h5 className='text-muted py-2 pt-3 col-12'>
													Contact Information
												</h5>

												<div className='form-group col-md-8'>
													<label>Address</label>
													<input
														type='text'
														className='form-control'
														name='address'
														placeholder='Address'
														//defaultValue={defaultValues.address}
														ref={register({
															required: 'Address is required!',
														})}
													/>
													<ErrorMsg errors={errors} name='address' />
												</div>

												<div className='form-group col-md-4'>
													<label>Local Govt.</label>
													<select
														className='form-control'
														name='lga'
														value={defaultValues.lga}
														onChange={(e) =>
															setSelectedLga(e.currentTarget.value)
														}
														ref={register({
															required: 'Local Govt. is required!',
														})}
													>
														<option value=''> - Select -</option>
														{lgas &&
															lgas.length > 0 &&
															lgas.map((row, index) => {
																return (
																	<option key={row.code} value={row.name}>
																		{row.name}
																	</option>
																);
															})}
													</select>
													<ErrorMsg errors={errors} name='lga' />
												</div>

												<div className='form-group col-md-6'>
													<label>Phone Number</label>
													<input
														type='text'
														className='form-control'
														name='phone'
														placeholder='Phone Number'
														defaultValue={defaultValues.phone}
														ref={register({
															required: 'Phone number is required!',
														})}
													/>
													<ErrorMsg errors={errors} name='phone' />
												</div>

												<div className='form-group col-md-6'>
													<label>Email Address</label>
													<input
														type='text'
														className='form-control'
														name='email'
														placeholder='Email Address'
														defaultValue={defaultValues.email}
														ref={register({
															validate: () =>
																validateEmail(getValues('email'), true),
														})}
													/>
													<ErrorMsg errors={errors} name='email' />
												</div>

												<h5 className='text-muted py-2 pt-3 col-12'>
													Medical Information
												</h5>

												<div className='form-group col-md-4'>
													<label>Genotype</label>
													<select
														className='form-control'
														name='genotype'
														defaultValue={defaultValues.genotype}
													>
														<option value=''>- Select -</option>
														<option value='AA'>AA</option>
														<option value='AS'>AS</option>
														<option value='SS'>SS</option>
														<option value='AC'>AC</option>
														<option value='SC'>SC</option>
													</select>
												</div>
												<div className='form-group col-md-4'>
													<label>Blood Group</label>
													<select
														className='form-control'
														name='blood_group'
														defaultValue={defaultValues.blood_group}
													>
														<option value=''>- Select -</option>
														<option value='A+'>A+</option>
														<option value='A-'>A-</option>
														<option value='B+'>B+</option>
														<option value='B-'>B-</option>
														<option value='AB+'>AB+</option>
														<option value='AB-'>AB-</option>
														<option value='O+'>O+</option>
														<option value='O-'>O-</option>
														<option value='O'>O</option>
													</select>
												</div>
												<div className='form-group col-md-4'>
													<label>Allergies</label>
													<input
														type='text'
														className='form-control'
														name='allergies'
														placeholder='Allergies'
														defaultValue={defaultValues.allergies}
													/>
												</div>

												<h5 className='text-muted py-2 pt-3 col-12'>
													Insurance Information
												</h5>

												<div className='form-group col-md-12'>
													<label>
														Primary Facility
														<br />
														<span className='text-muted font-size-12'>
															You can select more than one facility.
														</span>
													</label>
													<Controller
														name='facilities'
														control={control}
														rules={{ validate: validateFacility }}
														render={({ onChange, value }) => (
															<Select
																defaultValue={value}
																onChange={onChange}
																isMulti
																options={facilities}
																className='basic-multi-select'
																classNamePrefix='form-control'
															/>
														)}
													/>
													<ErrorMsg errors={errors} name='facilities' />
												</div>

												<hr />
											</div>
											<div className='text-right'>
												<button
													type='submit'
													className='btn btn-success btn-main'
												>
													Save Dependant
												</button>
											</div>
										</form>
									</div>
								</div>

								<div className='col-md-4 col-left-border'>
									<div className='card-body'>
										<h5>Primary Enrollee Info</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal
				show={modalState}
				onHide={handleCloseModal}
				animation={false}
				backdrop='static'
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>{modalTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{webCamError && <div className='text-error'>{webCamError}</div>}
					{!webCamError && (
						<Webcam
							audio={false}
							ref={webcamRef}
							screenshotFormat='image/jpeg'
							width={`100%`}
							onUserMediaError={() =>
								setWebCamError('No camera found on device/Pemission denied!')
							}
						/>
					)}
				</Modal.Body>
				<Modal.Footer>
					<input
						type='button'
						onClick={handleCloseModal}
						className='btn btn-default'
						value='Cancel'
					/>
					<button
						className='btn btn-success btn-main'
						onClick={capture}
						disabled={webCamError}
						type='button'
					>
						Capture Photo
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
