import * as React from 'react';
import AppContext from '../../../context';

import moment from 'moment';
import { useForm } from 'react-hook-form';
import { ErrorMsg, Currency } from './../../../components/Components';
import { InterswitchPay } from 'react-interswitch';

export default function RegisterInvoice({
	currentIndex,
	handleSubmitPayment,
	values,
}) {
	const { paymentMethods, paymentVendors, checkArray } =
		React.useContext(AppContext);
	const [invoice, setInvoice] = React.useState(null);
	const [paymentMeth, setPaymentMeth] = React.useState('bank_payment');
	const [paymentVendor, setPaymentVendor] = React.useState('');

	const { register, errors, handleSubmit, getValues } = useForm({
		criteriaMode: 'all',
		mode: 'onChange',
		defaultValues: {
			payment_method: paymentMeth,
		},
	});
	const [paymentComplete, setPaymentComplete] = React.useState(false);

	const paymentParameters = {
		merchantCode: process.env.REACT_APP_MERCHANT_CODE,
		payItemID: process.env.REACT_APP_PAY_ITEM_ID,
		customerEmail: values?.enrollee?.email,
		customerID: values?.enrollee?.phone,
		customerName:
			values?.enrollee?.first_name + ' ' + values?.enrollee?.last_name,
		redirectURL: 'http://localhost:3000',
		text: 'Pay Now',
		mode: process.env.REACT_APP_INTERSWITCH_MODE,
		transactionReference: Date.now().toString(),
		amount: values?.plan?.amount * 100,
		// amount: 10 * 100,
		style: {
			width: '200px',
			height: '40px',
			border: 'none',
			color: '#fff',
			backgroundColor: '#389e7f',
		},
		callback: (response) => {
			if (response.resp === '00') {
				const formData = getValues();

				formData.teller_ref = response.txnref;

				setPaymentComplete(true);

				onSubmit(formData);
			}
		},
	};

	React.useEffect(() => {
		setInvoice(values?.invoice);
	}, [values?.invoice]);

	const onSubmit = (data) => {
		data = {
			...data,
			transaction_id: invoice?.transaction_id,
			amount: invoice?.package?.amount,
			payment_date: new Date().toISOString(),
		};

		if (data.vendor === 'interswitch') {
			data.payment_method = 'bank_payment';
			data.bank_name = 'interswitch';
		}

		handleSubmitPayment(data);
	};

	return (
		<>
			{currentIndex === 3 && (
				<>
					<h5 className='text-dark mb-3'>Invoice Details</h5>

					<div className='invoice-title clearfix'>
						<h4 className='float-right font-size-16'>
							Invoice # {invoice?.transaction_id}
						</h4>
						{/* <div className="mb-4">
                    <img src="assets/images/logo-dark.png" alt="logo" height="20" />
                </div> */}
					</div>

					<hr />

					<div className='row'>
						<div className='col-6'>
							<address>
								<strong>Enrollee Details:</strong>
								<br />
								{invoice?.enrollee?.first_name} {invoice?.enrollee?.middle_name}{' '}
								{invoice?.enrollee?.last_name}
								<br />
								{invoice?.enrollee?.address}
								<br />
								{invoice?.enrollee?.lga}, {invoice?.enrollee?.state}
							</address>
						</div>

						<div className='col-6 text-right'>
							<address>
								<strong>Invoice Date:</strong>
								<br />
								{moment(invoice?.created_at).format('ll')}
								<br />
							</address>
						</div>
					</div>

					<div className='row'>
						<div className='col-6 mt-3'>
							<address>
								<strong>Package:</strong>
								<br />
								<b>{invoice?.package?.name}</b>
								<br />
								{values?.is_family_plan === 1 ? (
									<Currency value={invoice?.package?.family_amount} />
								) : (
									<Currency value={invoice?.package?.amount} />
								)}
							</address>
						</div>
					</div>

					<h5 className='text-dark py-3'>Payment Method</h5>
					<form
						id='form-registration-invoice'
						onSubmit={handleSubmit(onSubmit)}
					>
						{checkArray(paymentMethods) &&
							paymentMethods.map((row, index) => {
								return (
									<div
										key={row.value}
										className='custom-control custom-radio custom-control-select'
									>
										<input
											type='radio'
											id={`method-${row.value}`}
											value={row.value}
											disabled={row.disabled}
											name='payment_method'
											className='custom-control-input'
											ref={register({
												required: 'Please select a payment method!',
											})}
											onChange={(e) => setPaymentMeth(e.target.value)}
										/>
										<label
											className='custom-control-label custom-control-label-select custom-control-label-select-single'
											htmlFor={`method-${row.value}`}
										>
											<span>{row.label}</span>
										</label>
									</div>
								);
							})}

						<ErrorMsg errors={errors} name='payment_method' />

						{paymentMeth === 'pos' && (
							<div className='form-group col-12 px-0 '>
								<h5 className='text-dark py-3'>Teller Ref.</h5>
								<input
									type='text'
									className='p-3 w-100 rounded'
									style={{
										border: '1px solid #ccc',
									}}
									name='teller_ref'
									placeholder='Input teller reference '
									ref={register({
										required:
											'Teller reference is required for POS transaction',
									})}
								/>
								<ErrorMsg errors={errors} name='teller_ref' />
							</div>
						)}

						{paymentMeth === 'bank_payment' && (
							<div>
								<h5 className='text-dark py-3'>Payment Vendor</h5>
								{checkArray(paymentVendors) &&
									paymentVendors.map((row, index) => {
										return (
											<div
												key={row.value}
												className='custom-control custom-radio custom-control-select'
											>
												<input
													type='radio'
													id={`method-${row.value}`}
													value={row.value}
													disabled={row.disabled}
													name='vendor'
													className='custom-control-input'
													ref={register({
														required: 'Please select a payment Vendor!',
													})}
													onChange={(e) => setPaymentVendor(e.target.value)}
												/>
												<label
													className='custom-control-label custom-control-label-select custom-control-label-select-single'
													htmlFor={`method-${row.value}`}
												>
													<span>
														{/* {row.label} */}
														<a
															href='https://getvectorlogo.com/interswitch-group-vector-logo-svg/'
															target='_blank'
															rel='noreferrer'
															className='justify-content-center align-items-center'
														>
															{row.label === 'Interswitch' && (
																<img
																	src='https://getvectorlogo.com/wp-content/uploads/2023/05/interswitch-group-vector-logo.png'
																	width={70}
																	height={50}
																	alt='Interswitch'
																/>
															)}
														</a>
													</span>
												</label>
											</div>
										);
									})}
								<ErrorMsg errors={errors} name='vendor' />
							</div>
						)}

						{/* <div className="row">

                    {getValues("payment_method") !== 'exemption' && getValues("payment_method") !== '' && (
                        <div className="form-group col-12">
                                <label>Teller Ref.</label>
                                <input type="text" className="form-control"
                                    name="teller_ref"
                                    placeholder="Teller Ref."
                                    ref={register({
                                        required: "Teller ref. is required!"
                                    })}
                                />
                                <ErrorMsg errors={errors} name="teller_ref" />
                        </div>)}

                    {getValues("payment_method") === 'exemption' && (
                        <div className="form-group col-12">
                                <label>Exemption Category</label>

                                <select className="form-control"
                                    name="exemption_category"
                                    onChange={e => set_exemption_id(e.target.value)}
                                    ref={register({
                                        required: "Exemption is required!"
                                    })}>
                                    <option value=""> - Select -</option>
                                    {checkArray(exemptions) > 0 && exemptions.map((row, index) => {
                                        return (<option key={row.value} value={row.value}>{row.label}</option>)
                                    })}
                                </select>

                                <ErrorMsg errors={errors} name="exemption_category" />
                        </div>)}


                    {exemption_id === '1' && (
                        <div className="form-group col-12">
                                <label>Employee ID</label>
                                <input type="text" className="form-control"
                                    name="employee_number"
                                    placeholder="Employee ID"
                                    ref={register({
                                        required: "Employee ID is required!"
                                    })}
                                />
                                <ErrorMsg errors={errors} name="employee_number" />
                        </div>)}

                </div> */}
						{(paymentComplete ||
							paymentMeth === 'pos' ||
							paymentVendor === 'remita') && (
							<div className='text-right'>
								<button type='submit' className='btn btn-success btn-main'>
									Submit Registration
								</button>
							</div>
						)}
					</form>

					{paymentVendor === 'interswitch' && !paymentComplete && (
						<InterswitchPay {...paymentParameters} />
					)}
				</>
			)}
		</>
	);
}
