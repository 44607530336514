import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import AppContext from './../context';

import logo from '../assets/logo.png';

const HeaderNavbar = ({ children, history }) => {
	const { dispatch, userData, logoutUser, baseUrl } =
		React.useContext(AppContext);

	const handleLogout = () => {
		logoutUser(dispatch);
		history.replace('/login');
	};

	return (
		<header id='page-topbar'>
			<div className='navbar-header'>
				<div className='d-flex'>
					{/* <!-- LOGO --> */}
					<div className='navbar-brand-box'>
						<Link to='/' className='logo logo-dark'>
							<span className='logo-sm'>
								<img src={logo} alt='' width='50' />
							</span>

							<span className='logo-lg'>
								<img src={logo} alt='' width='50' />
							</span>
						</Link>

						<Link to='/' className='logo logo-light'>
							<span className='logo-sm'>
								<img
									src={`${baseUrl}assets/images/logo-light.svg`}
									alt=''
									height='22'
								/>
							</span>

							<span className='logo-lg'>
								<img
									src={`${baseUrl}assets/images/logo-light.png`}
									alt=''
									height='19'
								/>
							</span>
						</Link>
					</div>

					<button
						type='button'
						className='btn btn-sm px-3 font-size-16 header-item waves-effect'
						id='vertical-menu-btn'
					>
						<i className='fa fa-fw fa-bars'></i>
					</button>
					<div className='header-navbar-title'>EDOHIS AGGREGATORS PORTAL</div>
				</div>

				<div className='d-flex'>
					<div className='dropdown d-inline-block'>
						<button
							type='button'
							className='btn header-item waves-effect'
							id='page-header-user-dropdown'
							data-toggle='dropdown'
							aria-haspopup='true'
							aria-expanded='false'
						>
							<img
								className='rounded-circle header-profile-user'
								src={`${baseUrl}assets/images/icon-user.svg`}
								alt=''
							/>
							<span className='d-none d-xl-inline-block ml-1'>
								{userData?.first_name} {userData?.last_name}
							</span>
							<i className='mdi mdi-chevron-down d-none d-xl-inline-block'></i>
						</button>

						<div className='dropdown-menu dropdown-menu-right'>
							{/* <!-- item--> */}
							<Link className='dropdown-item' to='/change-password'>
								<i className='bx bx-wrench font-size-16 align-middle mr-1'></i>
								Settings
							</Link>

							<Link className='dropdown-item' to='/change-password'>
								<i className='bx bx-lock-open font-size-16 align-middle mr-1'></i>
								Change Password
							</Link>

							<div className='dropdown-divider'></div>

							<Link
								className='dropdown-item text-danger'
								onClick={handleLogout}
								to='/login'
							>
								<i className='bx bx-power-off font-size-16 align-middle mr-1 text-danger'></i>
								Logout
							</Link>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};
export default withRouter(HeaderNavbar);
