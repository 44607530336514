import * as React from 'react';

export default {
    setUserData: (dispatch, userToken, userData, userTokenExp) => {
        localStorage.setItem(process.env.REACT_APP_USER_DATA, JSON.stringify(userData));
        localStorage.setItem(process.env.REACT_APP_USER_TOKEN, userToken);
        localStorage.setItem(process.env.REACT_APP_USER_TOKEN_EXP, userTokenExp);
        dispatch({ type: 'LOGIN', userToken, userData, userTokenExp });
    },
    logoutUser: (dispatch) => {
        localStorage.removeItem(process.env.REACT_APP_USER_DATA);
        localStorage.removeItem(process.env.REACT_APP_USER_TOKEN);
        localStorage.removeItem(process.env.REACT_APP_USER_TOKEN_EXP);
        dispatch({ type: 'LOGOUT' });
    }
}