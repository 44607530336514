import * as React from 'react';
import AppContext from '../../../context';
import { useHistory } from 'react-router-dom';
import useModal from '../../../hooks/useModal';
import { Modal } from 'react-bootstrap';
import PrintID from '../print-id';

export default function RegisterDone({ currentIndex, values }) {
	const { dispatch, baseUrl } = React.useContext(AppContext);
	const { modalState, showModal, closeModal } = useModal();
	let history = useHistory();

	return (
		<>
			{currentIndex === 4 && (
				<>
					<div
						className='success-container clearfix'
						style={{
							margin: 'auto',
						}}
					>
						<img className='mb-3' src={`/assets/images/icon-checked.svg`} />
						<div className='display-block font-size-20 text-dark mb-3'>
							Enrollment Successful
						</div>
						<div className='success-container-info mb-3'>
							Enrollee has been successfully registered.
							<br />
							<strong>Your Enrollee ID is {values?.insurance_id}</strong>
						</div>

						<input
							type='button'
							onClick={() => history.push('/enrollees')}
							className='btn btn-success mr-1'
							value='Go back to Home'
						/>

						<button
							type='button'
							className='btn btn-secondary'
							onClick={showModal}
						>
							<i className='bx bx-printer font-size-16 align-middle mr-1'></i>{' '}
							Print Payment ID
						</button>
					</div>

					<Modal
						show={modalState}
						onHide={closeModal}
						animation={false}
						size='lg'
					>
						<PrintID closeModal={closeModal} data={values} />
					</Modal>
				</>
			)}
		</>
	);
}
