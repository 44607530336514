import React from "react";
import ReactToPrint from "react-to-print";
import { Modal } from 'react-bootstrap';
import moment from "moment";

//import IDCard from './../id-card';
//import './../print.css';


class ComponentToPrint extends React.Component {
    render() {
        const { first_name, middle_name, last_name, address, gender, phone,
            lga, state, insurance_id, package: packageDetails,
            picture, subscriptions } = this.props.data;
        const subscription = subscriptions && subscriptions.length > 0 && (subscriptions).pop();
        return (
            <div className="print-container">
                <div className="prt-header">
                    <div>
                        <img src='/assets/images/logo.svg' height='50' />
                    </div>
                    <div className="text-center">
                        <h5 className="text-secondary mb-1">Edo State Health Insurance Commission</h5>
                        <h6 className="mb-0">Ministry of Health</h6>
                    </div>
                    <div>
                        <img src='/assets/images/logo-edo.svg' height='48' />
                    </div>
                </div>

                <div className="print-container-inner">
                    <div className="prt-image-container">
                        <div className="prt-image-box">
                            {picture && <img src={picture} alt="" />}
                            {!picture && <i className="bx bx-user placeholder" style={{ fontSize: '50px' }}></i>}
                        </div>
                    </div>

                    <div className="prt-container">
                        <div className="row">
                            <div className="col-4 summary">
                                <label>First Name</label>{first_name}
                            </div>
                            <div className="col-4 summary">
                                <label>Middle Name</label>{middle_name}
                            </div>
                            <div className="col-4 summary">
                                <label>Last Name</label>{last_name}
                            </div>

                            <div className="col-6 summary">
                                <label>Address</label> {address}
                            </div>
                            <div className="col-6 summary">
                                <label>LGA of Residence</label>{lga}
                            </div>

                            <div className="col-6 summary">
                                <label>Gender</label>{gender}
                            </div>
                            <div className="col-6 summary">
                                <label>Phone Number</label>{phone}
                            </div>
                            <div className="col-12">
                                <hr />
                            </div>


                            <div className="col-12">
                                <h5>Insurance Info</h5>
                            </div>

                            <div className="col-4 summary">
                                <label>Insurance ID</label>{insurance_id}
                            </div>

                            <div className="col-4 summary">
                                <label>Package Name</label>{packageDetails?.name}
                            </div>

                            <div className="col-4 summary">
                                <label>Package Type</label>
                                {subscription?.is_family_plan === 1 ? 'Family Plan' : 'Individual Plan'}
                            </div>

                            <div className="col-6 summary">
                                <label>Start Date</label>
                                {moment(subscription?.start_date).format('ll')}
                            </div>

                            <div className="col-6 summary">
                                <label>End Date</label>
                                {moment(subscription?.expiration_date).format('ll')}
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

class PrintID extends React.Component {
    render() {
        return (<>
            <Modal.Body>
                <ComponentToPrint
                    data={this.props.data}
                    ref={(el) => (this.componentRef = el)} />
            </Modal.Body>

            <Modal.Footer>
                <input type="button"
                    onClick={this.props.closeModal}
                    className="btn btn-default" value="Cancel" />

                <ReactToPrint
                    trigger={() => (<button className="btn btn-secondary btn-main"
                        type="button">Print</button>)}
                    content={() => this.componentRef}
                />
            </Modal.Footer>
        </>

        );
    }
}

export default PrintID;
