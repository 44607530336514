import * as React from 'react';
import { Accordion, Card, Modal } from 'react-bootstrap';
import useResize from '../../hooks/useResize';
import { Currency } from '../../components/Components';
import AppContext from '../../context';
import { enrolleeData } from '../../Services';
import { useQuery } from 'react-query';

export default function EnrolleeDetails({ enrolleeDetails, handleCloseModal }) {
    const { setNA, formatDate, checkArray } = React.useContext(AppContext);
    const avatarContainerRef = React.useRef()
    const { width, height } = useResize(avatarContainerRef);
    const [data, setData] = React.useState(enrolleeDetails)

    const { data: enrolleeDt, isLoading, isFetching } =
        useQuery(["enrollee-details", enrolleeDetails.id], enrolleeData(enrolleeDetails.id));

    React.useEffect(() => {
        if (enrolleeDt) setData(enrolleeDt);

        return () => {
            setData({});
        };
    }, [enrolleeDt])

    return (<>

        <Modal.Body>

            <div className="row profile-details">
                <div className="col-md-4">

                    <div ref={avatarContainerRef} className="user-image-container">
                        <div className="user-image-box" style={{ width, height: width }}>
                            {!data?.picture && <i className="bx bx-user placeholder"></i>}
                            {data?.picture && <img src={data?.picture} />}
                        </div>

                        <div className="mt-3 text-center">
                            <h6 className="mb-2"><b>{data?.first_name} {data?.last_name}</b></h6>
                            <p className="mb-0">{data?.phone}</p>
                            <p className="mb-2">{data?.email}</p>
                            {data?.status === 'active' && (<span className="btn btn-sm btn-success">Account Active</span>)}
                            {data?.status === 'inactive' && (<span className="btn btn-sm btn-secondary">Account Inactive</span>)}
                        </div>


                    </div>


                </div>
                <div className="col-md-8">
                    <div className="row">
                        <div className="col-sm-4 summary">
                            <label>First Name</label>{data?.first_name}
                        </div>
                        <div className="col-sm-4 summary">
                            <label>Middle Name</label>{data?.middle_name}
                        </div>
                        <div className="col-sm-4 summary">
                            <label>Last Name</label>{data?.last_name}
                        </div>

                        <div className="col-sm-4 summary">
                            <label>Birth Date</label>{formatDate(data?.dob)}
                        </div>
                        <div className="col-sm-4 summary">
                            <label>Gender</label>{data?.gender}
                        </div>
                        <div className="col-sm-4 summary">
                            <label>Religion</label>{data?.religion}
                        </div>

                        <div className="col-12 summary">
                            <label>Address</label>
                            {data?.address}, {data?.lga}, {data?.state}.
                        </div>

                        <div className="col-sm-6 summary">
                            <label>Phone Number</label>
                            {data?.phone}
                        </div>
                        <div className="col-sm-6 summary">
                            <label>Email Address</label>
                            {data?.email}
                        </div>
                    </div>

                    {data?.business_name && (<>
                        <hr />
                        <h6 className="mb-1">Office Information</h6>

                        <div className="row">
                            <div className="col-12 summary">
                                <label>Business Name</label>{data?.business_name}
                            </div>
                            <div className="col-12 summary">
                                <label>Address</label>
                                {data?.office_address}, {data?.office_lga}, {data?.state}.
                        </div>
                        </div>
                    </>)}



                    <hr />
                    <h6 className="mb-1">Insurance Information</h6>
                    {data?.subscriptions && data?.subscriptions[0]?.is_family_plan === 1 &&
                        (<span className="badge badge-info font-size-12 mb-3">Family Plan</span>)}
                    {data?.subscriptions && data?.subscriptions[0]?.is_family_plan === 0 &&
                        (<span className="badge badge-info font-size-12 mb-3">Individual Plan</span>)}


                    <div className="row">
                        <div className="col-sm-6 summary">
                            <label>Package Name</label>
                            {setNA(data?.package?.name)}
                        </div>
                        <div className="col-sm-6 summary">
                            <label>Package Amount</label>
                            {data?.package?.amount ?
                                <Currency value={data?.package?.amount} /> :
                                (<span className="text-muted">N/A</span>)}
                        </div>

                        <div className="col-sm-6 summary">
                            <label>Start Date</label>
                            {data?.subscriptions && data?.subscriptions[0]?.start_date
                                ? formatDate(data?.subscriptions[0]?.start_date) : <span className="text-muted">N/A</span>}
                        </div>
                        <div className="col-sm-6 summary">
                            <label>End Date</label>
                            <span className="text-muted">
                                {data?.subscriptions && data?.subscriptions[0]?.expiration_date
                                    ? formatDate(data?.subscriptions[0]?.expiration_date) : <span className="text-muted">N/A</span>}
                            </span>
                        </div>

                        <div className="col-sm-6 summary">
                            <label>Insurance ID</label>
                            {setNA(data?.insurance_id)}
                        </div>
                    </div>

                    {data?.subscriptions && data?.subscriptions[0]?.is_family_plan === 1 && (<><h6 className="my-3">Dependants Information</h6>
                        <div className="accordion-format">
                            <Accordion>
                                {data?.dependants?.map((dp, index) => <Card key={index}>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                        {dp.first_name} {dp.last_name}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>


                                            <div className="row profile-details">
                                                <div className="col-md-12 mb-3">
                                                    <div className="user-image-container">
                                                        <div className="user-image-box m-auto"
                                                            style={{ width: '80px', height: '80px' }}>
                                                            <img src={dp.picture} />
                                                        </div>
                                                        <div className="mt-3 text-center">
                                                            <h6 className="mb-2"><b>{dp.first_name} {dp.last_name}</b></h6>
                                                        </div>
                                                    </div>
                                                </div>


                                                {/* col-8 */}
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-sm-4 summary"><label>First Name</label>{dp.first_name}</div>
                                                        <div className="col-sm-4 summary"><label>Middle Name</label>{dp.middle_name}</div>
                                                        <div className="col-sm-4 summary"><label>Last Name</label>{dp.last_name}</div>
                                                        <div className="col-sm-4 summary"><label>Birth Date</label>{formatDate(data?.dob)}</div>
                                                        <div className="col-sm-4 summary"><label>Gender</label>{dp.gender}</div>
                                                        <div className="col-sm-4 summary"><label>Religion</label>{dp.religion}</div>
                                                        <div className="col-sm-6 summary"><label>Phone Number</label>{dp.phone}</div>
                                                        <div className="col-sm-6 summary"><label>Email Address</label>{dp.email}</div>
                                                        <div className="col-12 summary"><label>Address</label>{dp.address}</div>
                                                    </div>
                                                </div>
                                                {/* /col-8 */}


                                            </div>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>)}
                            </Accordion>
                        </div></>)}

                    <hr />
                    <h6>Primary Facilities</h6>

                    {checkArray(data?.facilities) && data?.facilities.map((fa, index) => {
                        return (<div key={index} className="row mb-4">
                            <div className="col-12 summary">
                                <label>Facility Name</label>
                                <b className="text-info font-size-14">{setNA(fa?.name)}</b>
                            </div>

                            <div className="col-12 summary">
                                <label>Facility Address</label>
                                {setNA(fa?.address)},
                            {setNA(fa?.lga)},
                            {setNA(fa?.state)}.
                        </div>

                            <div className="col-sm-4 summary">
                                <label>Cantact Name</label>
                                {setNA(fa?.contacts[0]?.name)}
                            </div>

                            <div className="col-sm-4 summary">
                                <label>Contact Phone</label>
                                {setNA(fa?.contacts[0]?.phone)}
                            </div>
                            <div className="col-sm-4 summary">
                                <label>Contact Email</label>
                                {setNA(fa?.contacts[0]?.email)}
                            </div>
                        </div>)
                    })}


                    <hr />
                    <h6>Health Information</h6>

                    <div className="row">
                        <div className="col-sm-4 summary">
                            <label>Blood Group</label>
                            {setNA(data?.blood_group)}
                        </div>
                        <div className="col-sm-4 summary">
                            <label>genotype</label>
                            {setNA(data?.genotype)}
                        </div>
                        <div className="col-sm-4 summary">
                            <label>Allergies</label>
                            {setNA(data?.allergies)}
                        </div>
                    </div>


                </div>
            </div>


        </Modal.Body>

        <Modal.Footer>
            <input type="button" onClick={handleCloseModal} className="btn btn-default" value="Cancel" />
        </Modal.Footer>
    </>);
}