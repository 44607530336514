import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.timeout = process.env.REACT_APP_TIMEOUT;

axios.interceptors.request.use(
	async (config) => {
		const token =
			localStorage.getItem(process.env.REACT_APP_USER_TOKEN) || null;
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

const axiosJson = axios.create({
	baseURL: `${process.env.REACT_APP_API_URL}`,
	timeout: process.env.REACT_APP_TIMEOUT,
});

export async function login(values) {
	const { data } = await axios.post(`login`, values);
	return data;
}

/* AUTH */
export const changePassword = async (values) => {
	const { data } = await axios.post(`change-password`, values);
	return data;
};

/* FACILITY SERVICE */
export const services = async (query) => {
	const { data } = await axios.get(`services${query}`);
	return data;
};

export const serviceCreate = async (newdata) => {
	const { data } = await axios.post(`services/create`, newdata);
	return data;
};

export const serviceUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`services/${id}/update`, values);
	return data;
};

export const serviceDelete = async (id) => {
	const response = await axios.delete(`services/${id}/delete`);
	return response;
};

/* FACILITIES */
export const facilities = async (query) => {
	const { data } = await axios.get(`facilities${query}`);
	return data;
};

export const facilitiesList = async () => {
	const { data } = await axios.get(`facilities`);
	return data;
};

export const facilityCreate = async (newdata) => {
	const { data } = await axios.post(`facilities/create`, newdata);
	return data;
};

export const facilityUpdate = async ({ facilityId, values }) => {
	const { data } = await axios.put(`facilities/${facilityId}/update`, values);
	return data;
};

export const facilityDelete = async (facilityId) => {
	const response = await axios.delete(`facilities/${facilityId}/delete`);
	return response;
};

/* FACILITIES ASSESSMENT GROUP */
export const faGroup = async (query) => {
	const { data } = await axios.get(`question-groups${query}`);
	return data;
};

export const faGroupList = async () => {
	const { data } = await axios.get(`question-groups/list`);
	return data;
};

export const faGroupCreate = async (newdata) => {
	const { data } = await axios.post(`question-groups/create`, newdata);
	return data;
};

export const faGroupUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`question-groups/${id}/update`, values);
	return data;
};

export const faGroupDelete = async (id) => {
	const response = await axios.delete(`question-groups/${id}/delete`);
	return response;
};

/* FACILITIES ASSESSMENT Q&A */
export const faQuestions = async (query) => {
	const { data } = await axios.get(`questions${query}`);
	return data;
};

export const faQuestionsList = async () => {
	const { data } = await axios.get(`questions/list`);
	return data;
};

export const faQuestionCreate = async (newdata) => {
	const { data } = await axios.post(`questions/create`, newdata);
	return data;
};

export const faQuestionUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`questions/${id}/update`, values);
	return data;
};

export const faQuestionDelete = async (id) => {
	const response = await axios.delete(`questions/${id}/delete`);
	return response;
};

/* FACILITIES ASSESSMENT ANSWERS */
export const faAnswerSubmit = async ({ id, values }) => {
	const { data } = await axios.post(`facilities/${id}/answers`, values);
	return data;
};

/* ******** / ********* */

/* FACILITY SERVICE */
export const facilityTypes = async (query) => {
	const { data } = await axios.get(`facility-types${query}`);
	return data;
};

export const facilityTypesList = async () => {
	const { data } = await axios.get(`facility-types/list`);
	return data;
};

export const facilityTypeCreate = async (newdata) => {
	const { data } = await axios.post(`facility-types/create`, newdata);
	return data;
};

export const facilityTypeUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`facility-types/${id}/update`, values);
	return data;
};

export const facilityTypeDelete = async (id) => {
	const response = await axios.delete(`facility-types/${id}/delete`);
	return response;
};

/* FACILITY CONTACTS */
export const facilityContacts = async (facilityId, page, query = {}) => {
	const response = await axios.get(
		`facilities/${facilityId}/contacts?page=${page}${query}`
	);
	return response;
};

export const facilityContactView = async (facilityId, contactId) => {
	const response = await axios.get(
		`facilities/${facilityId}/contacts/${contactId}/view`
	);
	return response;
};

export const facilityContactCreate = async (facilityId, data) => {
	const response = await axios.post(
		`facilities/${facilityId}/contacts/create`,
		data
	);
	return response;
};

export const facilityContactUpdate = async (facilityId, contactId, data) => {
	const response = await axios.put(
		`facilities/${facilityId}/contacts/${contactId}/update`,
		data
	);
	return response;
};

export const facilityContactDelete = async (facilityId, contactId) => {
	const response = await axios.delete(
		`facilities/${facilityId}/contacts/${contactId}/delete`
	);
	return response;
};

/* BENEFITS */
export const benefits = async (query) => {
	const { data } = await axios.get(`benefits${query}`);
	return data;
};

export const benefitsList = async () => {
	const { data } = await axios.get(`benefits/list`);
	return data;
};

export const benefitCreate = async (values) => {
	const { data } = await axios.post(`benefits/create`, values);
	return data;
};

export const benefitUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`benefits/${id}/update`, values);
	return data;
};

export const benefitDelete = async (id) => {
	const response = await axios.delete(`benefits/${id}/delete`);
	return response;
};

/* ******** / ********* */

/* BENEFIT TYPES */
export const benefitTypes = async (query) => {
	const { data } = await axios.get(`benefit-types${query}`);
	return data;
};

export const benefitTypesList = async () => {
	const { data } = await axios.get(`benefit-types/list`);
	return data;
};

export const benefitTypeCreate = async (values) => {
	const { data } = await axios.post(`benefit-types/create`, values);
	return data;
};

export const benefitTypeUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`benefit-types/${id}/update`, values);
	return data;
};

export const benefitTypeDelete = async (id) => {
	const response = await axios.delete(`benefit-types/${id}/delete`);
	return response;
};

/* ******** / ********* */

/* PACKAGES */
export const packages = async (query) => {
	const { data } = await axios.get(`packages${query}`);
	return data;
};

export const packagesList = async () => {
	const { data } = await axios.get(`packages/list`);
	return data;
};

export const packageCreate = async (values) => {
	const { data } = await axios.post(`packages/create`, values);
	return data;
};

export const packageUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`packages/${id}/update`, values);
	return data;
};

export const packageDelete = async (id) => {
	const response = await axios.delete(`packages/${id}/delete`);
	return response;
};

/* ******** / ********* */

/* ADMIN USERS */
export const adminUserRoles = async () => {
	const { data } = await axios.get(`users/roles`);
	return data;
};

export const adminUsers = async (query) => {
	const { data } = await axios.get(`users${query}`);
	return data;
};

export const adminUserCreate = async (values) => {
	const { data } = await axios.post(`users/create`, values);
	return data;
};

export const adminUserUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`users/${id}/update`, values);
	return data;
};

export const adminUserDelete = async (id) => {
	const response = await axios.delete(`users/${id}/delete`);
	return response;
};

export const adminVerifyToken = async (token) => {
	const { data } = await axios.get(`auth/verify/token?token=${token}`);
	return data;
};

export const adminSetPassword = async (values) => {
	const { data } = await axios.post(`auth/password`, values);
	return data;
};

/* AGENTS */
export const agents = async (query) => {
	const { data } = await axios.get(`agents${query}`);
	return data;
};

export const agentCreate = async (values) => {
	const { data } = await axios.post(`agents/create`, values);
	return data;
};

export const agentUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`agents/${id}/update`, values);
	return data;
};

export const agentDelete = async (id) => {
	const response = await axios.delete(`agents/${id}/delete`);
	return response;
};

/* COMMON COLLECTIONS */
export const selectLgas = async () => {
	const { data } = await axios.get(`lgas`);
	return data;
};

/* ENROLLMENT */
export const enrollees = async (query) => {
	const { data } = await axios.get(`enrollees${query}`);
	return data;
};

export const registeredEnrollees = async (query) => {
	const { data } = await axios.get(`enrollees/registered${query}`);
	return data;
};

export const inactiveEnrollees = async (query) => {
	const { data } = await axios.get(`enrollees/not-enrolled${query}`);
	return data;
};

export const enrolleeCreate = async (values) => {
	const { data } = await axios.post(`enrollees/create`, values);
	return data;
};

export const enrolleeUpdate = async ({ id, values }) => {
	//console.log(id, values, 'enrolleeUpdate...')

	const { data } = await axios.post(`enrollees/${id}/update`, values);
	return data;
};

export const enrolleeAddPlan = async ({ id, values }) => {
	const { data } = await axios.post(`enrollees/${id}/add-plan`, values);

	return data;
};

export const enrolleeInvoice = async ({ id, transaction_id }) => {
	const { data } = await axios.get(
		`enrollees/${id}/invoice?transaction_id=${transaction_id}`
	);
	return data;
};

export const enrolleePay = async ({ id, values }) => {
	const { data } = await axios.post(`enrollees/${id}/pay-invoice`, values);
	return data;
};

export const enrolleeData = async (id) => {
	const { data } = await axios.get(`enrollees/${id}/view`);
	return data;
};

export const enrolleeAddDependant = async ({ id, values }) => {
	const { data } = await axios.post(`enrollees/${id}/add-dependant`, values);
	return data;
};

/* OCCUPATION */
export const occupations = async (query) => {
	const { data } = await axios.get(`occupations${query}`);
	return data;
};

export const occupationsList = async () => {
	const { data } = await axios.get(`occupations`);
	return data;
};

export const occupationCreate = async (values) => {
	const { data } = await axios.post(`occupations/create`, values);
	return data;
};

export const occupationUpdate = async ({ id, values }) => {
	const { data } = await axios.put(`occupations/${id}/update`, values);
	return data;
};

export const occupationDelete = async (id) => {
	const response = await axios.delete(`occupations/${id}/delete`);
	return response;
};

export const mdasList = async () => {
	const { data } = await axios.get(`mdas`);
	return data;
};

/* Reporting */

export const dashSummary = async () => {
	const { data } = await axios.get(`dashboard`);
	return data;
};
