import * as React from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function Form({ searchValues, onExportSubmit, resetExport, lgas, packages, searchType,
    closeModal, onSearchSubmit, setSearchValues, exporting, exportData, exportReady, mdas }) {

    const [startDate, setStartDate] = React.useState(searchValues?.start_date);
    const [endDate, setEndDate] = React.useState(searchValues?.end_date);
    const [type, setType] = React.useState(1);

    const form = document.getElementById('form-search');
    const allElements = form?.elements;


    React.useEffect(() => {
        //console.log(startDate, endDate, 'startDate_endDate...')
        setSearchValues({ ...searchValues, start_date: startDate, end_date: endDate })
    }, [startDate, endDate]);

    React.useEffect(() => {
        if (exporting) {
            if (allElements && allElements.length > 0) {
                for (let i = 0, l = allElements.length; i < l; ++i) {
                    allElements[i].disabled = true;
                }
            }
        } else {
            if (allElements && allElements.length > 0) {
                for (let i = 0, l = allElements.length; i < l; ++i) {
                    allElements[i].disabled = false;
                }
            }
        }
    }, [exporting]);

    const handleSearchChange = (e) => {
        const { name, value } = e.target
        setSearchValues({ ...searchValues, [name]: value })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        let newSearch = {};

        if ((searchValues?.start_date && !searchValues?.end_date) || (searchValues?.end_date && !searchValues?.start_date)) {
            alert('Both start and end date must be selected!');
            return;
        }

        if (searchValues) {
            Object.entries(searchValues).forEach(async ([key, value]) => {
                if (value) {
                    if (key === 'start_date' || key === 'end_date') {
                        newSearch = {
                            ...newSearch,
                            [key]: moment(value).format('YYYY-MM-DD')
                        }
                    } else {
                        newSearch = {
                            ...newSearch,
                            [key]: value
                        }
                    }
                }
            });
        }

        if (Object.keys(newSearch).length === 0) {
            alert('At least one search value is required!');
            return;
        }

        //console.log(newSearch, 'newSearch...');

        const { ['page']: remove, ...params } = newSearch;


        if (searchType === 1) {
            onSearchSubmit(params);
        } else {
            onExportSubmit(params);
        }
    }

    return (<>
        <form id='form-search' onSubmit={onSubmit}>

            <Modal.Body>
                {exportReady ? (<div className="py-3 text-center">
                    File is ready!
                </div>) : (<div className="row">
                    <div className="form-group col-6">
                        <input type="text"
                            value={searchValues?.q}
                            onChange={handleSearchChange}
                            className="form-control" name="q"
                            placeholder="First, middle, last name, phone..." />
                    </div>

                    <div className="form-group col-md-6">
                        <select className="form-control"
                            name="mda"
                            value={searchValues?.mda}
                            onChange={handleSearchChange}
                        >
                            <option value=""> - MDA -</option>
                            {mdas && mdas.length > 0 && mdas.map((row, index) => {
                                return (<option key={index} value={row}>{row}</option>)
                            })}
                        </select>
                    </div>

                    <div className="form-group col-md-6">
                        <select
                            className="form-control"
                            name="religion"
                            value={searchValues?.religion}
                            onChange={handleSearchChange}
                        >
                            <option value="">- Religion -</option>
                            <option value="Christian">Christian</option>
                            <option value="Muslim">Muslim</option>
                        </select>
                    </div>

                    <div className="form-group col-md-6">
                        <select className="form-control"
                            name="gender"
                            value={searchValues?.gender}
                            onChange={handleSearchChange}
                        >
                            <option value="">- Gender -</option>
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                        </select>
                    </div>

                    <div className="form-group col-md-6">
                        <select className="form-control"
                            name="lga"
                            value={searchValues?.lga}
                            onChange={handleSearchChange}
                        >
                            <option value=""> - LGA -</option>
                            {lgas && lgas.length > 0 && lgas.map((row, index) => {
                                return (<option key={row.code} value={row.name}>{row.name}</option>)
                            })}
                        </select>
                    </div>

                    <div className="form-group col-md-6">
                        <select className="form-control"
                            name="plan_id"
                            value={searchValues?.plan_id}
                            onChange={handleSearchChange}
                        >
                            <option value=""> - Plan -</option>
                            {packages && packages.length > 0 && packages.map((row, index) => {
                                return (<option key={row.id} value={row.id}>{row.name}</option>)
                            })}
                        </select>
                    </div>

                    <div className="col-12">
                        <div className="input-group input-group-picker">

                            <DatePicker
                                className="form-control"
                                autoComplete="off"
                                name="start_date"
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="Reg. start date"
                                isClearable
                                dateFormat="MMM d, yyyy"
                            />
                            <DatePicker
                                className="form-control"
                                autoComplete="off"
                                name="end_date"
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText="Reg. end date"
                                isClearable
                                dateFormat="MMM d, yyyy"
                            />
                        </div>
                    </div>



                </div>)}
            </Modal.Body>

            <Modal.Footer style={{ justifyContent: 'space-between' }}>
                {searchType === 1 ? (<>
                    <input type="button" onClick={closeModal} className="btn btn-default" value="Close" />
                    <button className="btn btn-success btn-main" onClick={() => setType(1)} type="submit">Submit Search</button>
                </>) : (<>
                    <input type="button" onClick={resetExport} className="btn btn-default" value="Close" />

                    {exportReady ? (<>

                        <ExcelFile element={<button className="btn btn-success btn-download" type="button">Download file</button>}>
                            <ExcelSheet data={exportData} name="Enrollees">
                                <ExcelColumn label="First Name" value="first_name" />
                                <ExcelColumn label="Middle Name" value="middle_name" />
                                <ExcelColumn label="Last Name" value="last_name" />
                                <ExcelColumn label="Gender" value="gender" />
                                <ExcelColumn label="Religion" value="religion" />
                                <ExcelColumn label="Occupation" value="occupation" />
                                <ExcelColumn label="Birth Date" value={(col) => col.dob ? moment(col.dob).format('ll') : col.dob} />
                                <ExcelColumn label="Marital Status" value="marital_status" />
                                <ExcelColumn label="Educational Status" value="educational_status" />
                                <ExcelColumn label="Special Needs" value="special_needs" />
                                <ExcelColumn label="NIN" value="nin" />

                                <ExcelColumn label="Address" value="address" />
                                <ExcelColumn label="LGA. of Residence" value="lga" />
                                <ExcelColumn label="State" value="state" />
                                <ExcelColumn label="Ward" value="ward" />
                                <ExcelColumn label="Community" value="community" />
                                <ExcelColumn label="Phone Number" value="phone" />
                                <ExcelColumn label="Email Address" value="email" />

                                <ExcelColumn label="Business Name" value="business_name" />
                                <ExcelColumn label="Office Address" value="office_address" />
                                <ExcelColumn label="Office LGA" value="office_lga" />

                                <ExcelColumn label="Genotype" value="genotype" />
                                <ExcelColumn label="Blood Group" value="blood_group" />
                                <ExcelColumn label="Allergies" value="allergies" />
                            </ExcelSheet>
                        </ExcelFile>
                    </>) : (<>

                        <button
                            className="btn btn-secondary btn-export"
                            type="submit"
                            onClick={() => setType(2)}
                        >
                            {exporting ? `Exporting (${exportData.length})...` : 'Export to Excel'}

                        </button>

                    </>)}

                </>)}

            </Modal.Footer>
        </form>
    </>);
}