import * as React from 'react';
import AppContext from '../../../context';
import {
	packages as selectPackages,
	enrolleeCreate,
	selectLgas,
	occupationsList,
	enrolleeData,
	enrolleeAddPlan,
	enrolleeInvoice,
	enrolleePay,
	facilitiesList as selectFacilities,
	enrolleeUpdate,
} from '../../../Services';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import Form from './../form';
import useModal from './../../../hooks/useModal';
import { useForm } from 'react-hook-form';
import { StepIndicator } from './../../../components/Components';

import RegisterData from './RegisterData';
import RegisterPackage from './RegisterPackage';
import RegisterInvoice from './RegisterInvoice';
import RegisterDone from './RegisterDone';
import useFormState from './../../../hooks/useFormState';

export default function Register({ location, history }) {
	const { dispatch, baseUrl, setPageTitle, capitalize, errorResponse, notify } =
		React.useContext(AppContext);

	const queryClient = useQueryClient();
	const editMode = location.state?.editMode || false;
	const continueData = location.state?.continueData || null;

	const [currentIndex, setCurrentIndex] = React.useState(1);
	const [values, setValues] = React.useState({ enrollee: null });
	const [packages, setPackages] = React.useState([]);
	const [lgas, setLgas] = React.useState([]);
	const [facilitiesData, setFacilitiesData] = React.useState([]);
	const [occupations, setOccupations] = React.useState([]);
	const [enrolleeImage, setEnrolleeImage] = React.useState(null);
	const [enrolleeImageData, setEnrolleeImageData] = React.useState(null);
	const [blobPhoto, setBlobPhoto] = React.useState('');
	const [photoMode, setPhotoMode] = React.useState(1);

	const updateValues = (name, value) => {
		setValues({ ...values, [name]: value });
	};

	const { toggleFormState: tfData } = useFormState('form-register-data');
	const { toggleFormState: tfPackage } = useFormState('form-register-package');
	const { toggleFormState: tfInvoice } = useFormState(
		'form-registration-invoice'
	);

	/* Packages */
	useQuery('select-packages', () => selectPackages(''), {
		onError: (error) => setPackages([]),
		onSuccess: (data) => setPackages(data),
	});

	//TODO: Remove temp lga
	//const selectLgas = [{ code: 'OR', name: 'Oredo' }]

	/* Local Govt. */
	useQuery('select-lgas', selectLgas, {
		onError: (error) => setLgas([]),
		onSuccess: (data) => setLgas(data),
		//onSuccess: (data) => setLgas([{ code: 'OR', name: 'Oredo' }]),
	});

	/* Facilities. */
	useQuery('select-facilities', selectFacilities, {
		onError: (error) => setFacilitiesData([]),
		onSuccess: (data) => {
			if (data.length > 0) {
				const setFac = data.map((dt) => ({
					value: dt.id,
					label: `${dt.name}`,
					lga: dt.lga,
				}));
				setFacilitiesData(setFac);
			}
		},
	});

	/* Occupations. */
	useQuery('select-occupations', occupationsList, {
		onError: (error) => setOccupations([]),
		onSuccess: (data) => setOccupations(data),
	});

	/* Fetch invoice package successfully submited */
	const { isIdle } = useQuery(
		'invoice',
		() =>
			enrolleeInvoice({
				id: values?.plan?.enrollee_id,
				transaction_id: values?.plan?.transaction_id,
			}),
		{
			enabled: Boolean(values?.plan),
			onSuccess: (data) => {
				updateValues('invoice', data);
			},
		}
	);

	const { mutate: handleSubmitEnrollement } = useMutation(
		(values) => enrolleeCreate(values),
		{
			onSuccess: (data) => {
				updateValues('enrollee', data);
				setCurrentIndex(2);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => tfData(false),
		}
	);

	const { mutate: editPersonalInfo } = useMutation(
		({ id, values }) => enrolleeUpdate({ id, values }),
		{
			onSuccess: () => {
				history.push('/enrollees');
				notify(
					'success',
					'Updated Successful!',
					'Enrollee updated successfully.'
				);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => tfData(false),
		}
	);

	/* Handle step 1 */
	const submitEnrollement = async (data) => {
		//console.log(data, 'data...')

		tfData(true, 'saving...');
		let formData = new FormData();
		if (photoMode) {
			data = {
				...data,
				photo: photoMode === 2 ? data?.photo[0] : blobPhoto,
			};
		}

		data = {
			...data,
			dob: moment(data?.dob).format('YYYY-MM-DD'),
			//photo: (photoMode === 2 ? data?.photo[0] : blobPhoto),
			facilities:
				data?.facilities &&
				JSON.stringify(data.facilities.map((dt) => dt.value)),
			state: 'Edo State',
		};

		Object.entries(data).forEach(async ([key, value]) => {
			await new Promise((resolve) => formData.append(key, value));
		});
		if (editMode) {
			editPersonalInfo({ id: continueData.id, values: formData });
		} else {
			handleSubmitEnrollement(formData);
		}
	};

	const { mutate: submitPackage } = useMutation(
		({ id, values }) => enrolleeAddPlan({ id, values }),
		{
			onSuccess: (data) => {
				updateValues('plan', data);
				setCurrentIndex(3);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => tfPackage(false),
		}
	);

	/* Handle step 2 */
	const handleSubmitPackage = (data) => {
		tfPackage(true, 'saving...');

		const post = {
			id: values?.enrollee?.id,
			values: {
				...data,
				is_family_plan: data.is_family_plan === 'true' ? true : false,
				start_date: moment(data?.start_date).format('YYYY-MM-DD'),
			},
		};

		submitPackage(post);
	};

	/* Handle step 3 */
	const handleSubmitPayment = (data) => {
		const post = {
			id: values.enrollee.id,
			values: data,
		};

		submitEnrolleePayment(post);
	};

	/* Final step, submit payment */
	const { mutate: submitEnrolleePayment } = useMutation(
		({ id, values }) => enrolleePay({ id, values }),
		{
			onSuccess: ({ RRR, amount, enrollee, subscription }) => {
				const dt = { ...enrollee, amount, RRR, ...subscription };
				//console.log(dt, 'data...');
				setValues(dt);
				setCurrentIndex(4);
			},
			onError: (error) => errorResponse({ error, dispatch }),
			onSettled: () => tfInvoice(false),
		}
	);

	const processImage = (event) => {
		const file = event.target.files || event.dataTransfer.files;
		if (file[0]) {
			setEnrolleeImageData(file[0]);
			let reader = new FileReader();
			reader.onload = (e) => {
				setEnrolleeImage(e.target.result);
			};
			reader.readAsDataURL(file[0]);
		}
	};

	React.useEffect(() => {
		//enrolleeData(continueData?.id)
		if (continueData) {
			(async () => {
				let enrolleeDt = await enrolleeData(continueData?.id);
				if (enrolleeDt) {
					Object.entries(enrolleeDt).forEach(([key, value]) => {
						enrolleeDt = {
							...enrolleeDt,
							[key]:
								value === null ||
								value === 'null' ||
								value === undefined ||
								value === 'Undefined' ||
								value === 'undefined'
									? ''
									: value,
						};
					});

					const {
						first_name,
						middle_name,
						last_name,
						gender,
						religion,
						dob,
						occupation,
						educational_status,
						marital_status,
						special_needs,
						nin,
						address,
						lga,
						state,
						phone,
						email,
						facilities,
						picture,
						genotype,
						blood_group,
						allergies,
						business_name,
						office_address,
						office_lga,
					} = enrolleeDt;

					let enrollee = {
						first_name,
						middle_name,
						last_name,
						gender,
						religion,
						occupation,
						educational_status,
						marital_status,
						special_needs,
						nin,
						picture,
						genotype,
						blood_group,
						allergies,
						dob: dob ? moment(dob).toDate() : '',
						address,
						lga: capitalize(lga),
						state,
						phone,
						email,
						business_name,
						office_address,
						office_lga: capitalize(office_lga),
						facilities:
							(facilities &&
								facilities.length > 0 &&
								facilities.map((dt) => ({ label: dt.name, value: dt.id }))) ||
							[],
					};

					setValues({ ...values, enrollee });
					setCurrentIndex(1);
					//console.log(enrollee, 'enrollee...');
				}
			})();
			if (editMode) {
				setPageTitle(dispatch, 'Edit Enrollee');
				setPhotoMode(null);
			}
		}
	}, [continueData]);

	return (
		<>
			<div className='row'>
				<div className='col-md-12'>
					<div className='card'>
						<div className='card-body p-0'>
							<div className='row'>
								<div className={currentIndex !== 4 ? 'col-md-8' : 'col-12'}>
									<div className='card-body'>
										{currentIndex !== 4 && (
											<ul className='steps-block'>
												{[...Array(3)].map((e, index) => {
													index = index + 1;
													const active = currentIndex === index ? 'active' : '';
													const done = index < currentIndex ? 'done' : '';

													return (
														<li key={index} className={`${done} ${active}`}>
															<span>
																{done ? (
																	<i className='fas fa-check'></i>
																) : (
																	index
																)}
															</span>
														</li>
													);
												})}
											</ul>
										)}

										{continueData && values?.enrollee && (
											<RegisterData
												currentIndex={currentIndex}
												setCurrentIndex={setCurrentIndex}
												lgas={lgas}
												submitEnrollement={submitEnrollement}
												processImage={processImage}
												enrolleeImage={enrolleeImage}
												setBlobPhoto={setBlobPhoto}
												setEnrolleeImage={setEnrolleeImage}
												photoMode={photoMode}
												setPhotoMode={setPhotoMode}
												blobPhoto={blobPhoto}
												occupations={occupations}
												facilitiesData={facilitiesData}
												values={values?.enrollee}
												editMode={editMode}
											/>
										)}

										{!values?.enrollee && (
											<RegisterData
												currentIndex={currentIndex}
												setCurrentIndex={setCurrentIndex}
												lgas={lgas}
												submitEnrollement={submitEnrollement}
												processImage={processImage}
												enrolleeImage={enrolleeImage}
												setBlobPhoto={setBlobPhoto}
												setEnrolleeImage={setEnrolleeImage}
												photoMode={photoMode}
												setPhotoMode={setPhotoMode}
												blobPhoto={blobPhoto}
												occupations={occupations}
												facilitiesData={facilitiesData}
												editMode={editMode}
											/>
										)}

										<RegisterPackage
											currentIndex={currentIndex}
											setCurrentIndex={setCurrentIndex}
											packages={packages}
											handleSubmitPackage={handleSubmitPackage}
										/>

										<RegisterInvoice
											currentIndex={currentIndex}
											setCurrentIndex={setCurrentIndex}
											values={values}
											handleSubmitPayment={handleSubmitPayment}
										/>

										{/* <RegisterPayment
                                        currentIndex={currentIndex}
                                        setCurrentIndex={setCurrentIndex}
                                        handleSubmitPayment={handleSubmitPayment}
                                        values={values}
                                    /> */}

										<RegisterDone
											currentIndex={currentIndex}
											setCurrentIndex={setCurrentIndex}
											values={values}
										/>
									</div>
								</div>

								{currentIndex !== 4 && (
									<div className='col-md-4 col-left-border'>
										<div className='card-body'>
											<h5>More Information</h5>
											<p>
												See all the information you need about our insurance
												plan.
											</p>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
