import * as React from 'react';
import AppContext from '../../context';
import { Currency, NumFormat } from './../../components/Components';
import ReactMapGL, { Marker } from 'react-map-gl';
import Chart from 'react-apexcharts';
import eso from './enrollment-summary-options';

import { dashSummary } from '../../Services';
import { useQuery } from 'react-query';

export default function Dashboard({ history }) {
	const {
		dispatch,
		togglePageTitle,
		mapboxApiAccessToken,
		checkArray,
		checkObject,
		setQueryString,
		errorResponse,
	} = React.useContext(AppContext);

	const [dashData, setDashData] = React.useState({});

	React.useEffect(() => {
		togglePageTitle(dispatch, false);
		return () => togglePageTitle(dispatch, true);
	}, []);

	useQuery('dashboard-data', dashSummary, {
		retry: 0,
		//manual: true,
		onSuccess: (data) => setDashData(data),
		onError: (error) => errorResponse({ error, history, dispatch }),
	});

	return (
		<>
			<div className='row'>
				<div className='col-sm-6'>
					<div className='dash-card dash-card-info dash-icon-1'>
						<div className='dci-title'>Total Enrollees Registered</div>
						<div className='dci-content'>
							{<NumFormat value={dashData?.enrollee_count || 0} />}
						</div>
					</div>
				</div>

				<div className='col-sm-6'>
					<div className='dash-card dash-card-info dash-icon-2'>
						<div className='dci-title'>Total Number of Registrants</div>
						<div className='dci-content'>
							{dashData?.registrants_count || 0}
						</div>
					</div>
				</div>

				<div className='col-sm-6'>
					<div className='dash-card dash-card-info dash-icon-3'>
						<div className='dci-title'>Total Premium Collected</div>
						<div className='dci-content'>
							{<Currency value={dashData?.totalRevenue || 0} />}
						</div>
					</div>
				</div>

				<div className='col-sm-6'>
					<div className='dash-card dash-card-info dash-icon-4'>
						<div className='dci-title'>Commission Owed</div>
						<div className='dci-content'>
							{<Currency value={dashData?.commission_owed || 0} />}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
